export default {
    baseurl(state)  {
        //TODO remember to convert back to Production URL
        //  return "http://127.0.0.1:8000/api";
        return "https://hotspot.tizetiwifi.com/api";
         //   return state.enviroment=="development"? "http://127.0.0.1:8000/api" : "https://hotspot.tizetiwifi.com/api";
    },
    paystack_keys(state){
        return "pk_live_48dd0c126b30e969b1b7eff9f27ded163647f4c6";
        // return "pk_test_e1929473ff607af44d68b320871309eba86d377f";
   //   return state.enviroment == 'development' ? "pk_test_e1929473ff607af44d68b320871309eba86d377f" : "pk_live_48dd0c126b30e969b1b7eff9f27ded163647f4c6";
    },
    enviroment(state) {
        return state.enviroment
    },

    token(state){
        return state.token
    },
    isAuthenticated(state){
        return !!state.token
    },
    getprimaryaccount(state){
        return state.user.primaryaccount
    },
    getUser(state){
        return state.user
    }
}
