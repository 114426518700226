export default {

    products(state){
        return state.products
    },

    eutelsat_products(state){
        return state.eutelsat_products
    }


}
