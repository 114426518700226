<template>
  <div class="flex flex-col justify-between bg-white rounded shadow-lg w-full mt-4 lg:px-5 min-h-screen">
    <div v-if= "issues.data.length" class="flex flex-col space-y-1 w-full mt-10 text-expressgray  px-1 mb-5">
    <div class="flex items-center justify-between  space-x-4 border-b-2 p-4">
     <h2 class="md:text-2xl text-lg sm:pl-10">Support</h2>
     <div class="flex items-center sm:space-x-20 space-x-12">
      <h2 class="md:text-2xl text-lg">Status</h2>
      <h2 class="md:text-2xl text-lg">Action</h2>
     </div>

    </div>

              <div v-for="issue in issues.data" :key="issue.id">
                  <div class="flex  items-center  justify-between  space-x-4 border-b-2 p-4">
                    <div class="flex items-center space-x-4 ">
                      <svg class="w-6 h-6 fill-yellow" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M2 6a2 2 0 012-2h12a2 2 0 012 2v2a2 2 0 100 4v2a2 2 0 01-2 2H4a2 2 0 01-2-2v-2a2 2 0 100-4V6z"></path></svg>
                      <div class="div flex flex-col">
                        
                          <p class="md:text-2xl sm:text-lg text-sm">{{issue.title}}</p>
                          <p class="md:text-sm text-xs">{{getHumanDate(issue.created_at) }}</p>
                      </div>

                  </div>

          <div class="flex items-center space-x-4">

                <span
                   v-if="issue.status === 'Under Investigation' "
                   class="rounded-2xl bg-yellow-600 text-white md:p-3 p-2 text-center md:text-sm text-xs">
                       {{issue.status}}
                </span>

                 <span
                    v-else
                    class="rounded-2xl bg-green-600 text-white md:p-3 p-2 text-center md:text-sm text-xs">
                       {{ issue.status}}
                 </span>


            <router-link
                :to="generate_issue_url(issue.id)">
              <p class="border-2 p-3 cursor-pointer md:text-base text-xs"> Details </p>
            </router-link>

          </div>
        </div>

      </div>

      </div>

      <div v-else>
          <p class="text-center text-2xl">No issues found</p>
      </div>

       <div class="mx-auto ">
                <pagination :total-pages="totalPages"
                            :total="total"
                            :per-page="perPage"
                            :current-page="currentPage"
                            :has-more-pages="hasMorePages"
                            @pagechanged="showMore">
                </pagination>
              </div>
  </div>
</template>

<script>

import { mapGetters } from 'vuex'
import moment from 'moment'

export default {
 name: "SupportTicket",

 created(){

          this.totalPages = this.issues.to
          this.total = this.issues.total
          this.perPage = this.issues.per_page
          this.currentPage = this.issues.current_page
          this.hasMorePages = this.issues.last_page > this.issues.current_page



        this.$store.dispatch('issue/action_issues',{
            toast:this.$toast,
            router:this.$router
          });

          console.log('issues',this.issues)
  },
  data(){
    return {
      showfilter:false,
        totalPages: 1,
        total: 10,
        perPage: 5,
        currentPage :  1,
        hasMorePages: true,
        isLoading:false,
        showModel: false
    }
  },

  computed:{
        ...mapGetters({
          issues: 'issue/issues',
           getUser: 'authentication/getUser',
        })},

  methods: {
     getHumanDate : function (date) {
                return moment(date, 'YYYY-MM-DD').fromNow();
                // .format('Do MMMM,YYYY');
     },
      generate_issue_url: function (topic_uid){
          return `/report/${topic_uid}/details`
        },
    // generate_issue_url_admin: function (topic_uid){
    //   return `/report/${topic_uid}/details`
    // }
  }
}
</script>

<style>

</style>
