import axios from 'axios'


export default {

    action_setTopicUid ({ context,state, commit, rootState },payload){ // eslint-disable-line no-unused-vars
        commit('setTopicUid', payload.topic_uid)
    },

    action_add_comment_to_topic ({ context,state, commit, rootState },payload){ // eslint-disable-line no-unused-vars
        const url = `${rootState.baseurl}/comment`
        const token = rootState.authentication.token
        const comment_uid = payload.comment_uid
        const support_id = payload.support_id
        const message = payload.message
        const status = payload.status
        const category = payload.category



        const body = {
            comment_uid: comment_uid,
            support_id: support_id,
            category: category,
            message: message,
            status: status
           }

           axios.post(url, body, {headers: { 'Accept': 'application/json', 'Authorization' : 'Bearer '+ token}})
            .then(response => {
                console.log("response", response)
                payload.toast.success('Comment added')
            })
            .catch((error)=>{
                console.log("error", error)
                payload.toast.error('An error occurred, please try again')
            })


            ;




    },
    action_fetch_specific_topic ({ context,state, commit, rootState },payload){ // eslint-disable-line no-unused-vars
        const url = `${rootState.baseurl}/support/${payload.id}`
        const token = rootState.authentication.token
        axios.get(url, {headers: { 'Accept': 'application/json', 'Authorization' : 'Bearer '+ token}}).then(response => {
           // console.log('issues', response.data)
           if(response.data.status){
            commit('setSpecificIssue', response.data)
           }

        }).catch((error)=>{
            console.log("error", error)
            payload.toast.error('An error occurred, please try again')
        })


    },
    action_fetch_specific_topic_comments ({ context,state, commit, rootState },payload){ // eslint-disable-line no-unused-vars
        const url = `${rootState.baseurl}/comment/${parseInt(payload.id)}`
        // ${payload.id}`
        const token = rootState.authentication.token
        const headers =  {
            'Content-Type': 'application/json',
             'Accept': 'application/json',
             'Authorization' : 'Bearer '+ token
            }
        axios.get(url,{headers} )

        .then(response => {
            //console.log('comments', response.data.comments)
           if(response.data.status){
            commit('setComments', response.data.comments)
           }

        }).catch((error)=>{
            console.log("error", error)
            payload.toast.error('An error occurred, please try again')
        })


    },
    action_issues ({ context,state, commit, rootState },payload){ // eslint-disable-line no-unused-vars
        const url = rootState.baseurl+"/support"
         const token = rootState.authentication.token

        axios.get(url, {headers: { 'Accept': 'application/json', 'Authorization' : 'Bearer '+ token}}).then(response => {
            console.log('issues', response.data)

            commit('setIssues', response.data)
        }).catch((error)=>{
            console.log("error", error)
            payload.toast.error('An error occurred, please try again')
        })

    },

    async action_create_support_issue({ context,state, commit, rootState },payload){ // eslint-disable-line no-unused-vars
        const url = rootState.baseurl+"/support"
        const token = rootState.authentication.token
        const  topic_uid = payload.topic_uid
        const category = payload.category
        const title = payload.title
        const message = payload.message

        const body = {
           topic_uid: topic_uid,
           category: category,
           title: title,
           message: message
           }



      let mypromise = await axios.post(url, body, {headers: { 'Accept': 'application/json', 'Authorization' : 'Bearer '+ token}})
      .then(response => {
        //    console.log('issue', response.data)
          // commit('setIssues', response.data)
        //   payload.toast.success("Support created successfully")
        //   payload.router.replace('/support')


        let promise = new Promise(function(resolve, reject) {
            // console.log('action data', data)
            resolve(true)
            if(!response.data.status){
                reject(false)
            }
              // Make an asynchronous call and either resolve or reject
        });

        return promise

       })

       return mypromise
    },

    async action_upload_step_1_file({ context,state, commit, rootState },payload){ // eslint-disable-line no-unused-vars
        const url = rootState.baseurl+"/file/upload/url"
        const token = rootState.authentication.token

        const formData = new FormData();
              formData.append("image", payload.file);
               let mypromise = await axios.post(url, formData, {headers: { 'Accept': 'application/json', 'Content-Type':'multipart/form-data', 'Authorization' : 'Bearer '+ token}})
                  .then(response => {
                  let data = response.data
                  let promise = new Promise(function(resolve, reject) {
                      console.log('action data', data)
                      resolve(data.url)
                      if(data.url == ''){
                          reject('')
                      }
                        // Make an asynchronous call and either resolve or reject
                  });

                  return promise


        })

        return mypromise

    },

    action_upload_step_2_file_url({ context,state, commit, rootState },payload){ // eslint-disable-line no-unused-vars
        const url = rootState.baseurl+"/image"
        const token = rootState.authentication.token
        const reference = payload.reference
        const section   = payload.section
        const path = payload.path

        const body = {
            reference: reference,
            section: section,
            path: path
         }


         axios.post(url, body, {headers: { 'Accept': 'application/json', 'Content-Type':'application/json', 'Authorization' : 'Bearer '+ token}}).then(response => {
          console.log("step 2 uploaded", response.data)

        }).catch((error)=>{
            console.log("error", error)
            payload.toast.error('An error occurred, please try again')
        })

    }
}
