<template>
  <div class="flex flex-col rounded w-full pt-4 px-1 min-h-screen space-y-6">
    <div><h2 class="text-xl text-center lg:pl-10 uppercase lg:text-left mt-5 font-semibold font-serif">Total Retailers</h2></div>
    <!-- <div class="flex space-x-4 rounded shadow-lg bg-white p-5 justify-between w-full">
      <summarycard imageurl="https://res.cloudinary.com/dcsensx8u/image/upload/v1643862532/Screenshot_2022-02-03_at_5.28.45_AM.png"  title="Retailers" label="705"/>
      <summarycard imageurl="https://res.cloudinary.com/dcsensx8u/image/upload/v1643860970/Screenshot_2022-02-03_at_5.02.43_AM.png"  title="This Month" label="5,000,000 NGN"/>
      <summarycard imageurl="https://res.cloudinary.com/dcsensx8u/image/upload/v1643860970/Screenshot_2022-02-03_at_5.02.43_AM.png"  title="Total Purchase" label="75,000,000 NGN"/>
    </div> -->



    <div class="flex flex-col rounded shadow-lg bg-white w-full mt-10 text-expressgray mb-5 h-full">
      <div class="overflow-x-scroll">
<table class="w-full">
  <tr class="border-b h-14 border-ndigo-100 text-center bg-indigo-50">
    <th class="p-6">Name</th>
    <th class="p-6">Mobile</th>
    <th class="p-6"> Old Wallet Balance</th>
    <th class="p-6">New Wallet Balance</th>
    <th class="p-6">Recharge Amount</th>
    <th class="p-6">Currency</th>
    <th class="p-6">Payment Reference</th>
    <th class="p-6">Added By</th>
     <th class="p-6">Country</th>
     <th class="p-6">Date</th>
  </tr>
  <tr class="border-b h-14 border-gray-600 text-center" v-for="(item,index) in retailer_purchase_list" :key="index">
    <td class="p-6">{{item.name}}</td>
    <td class="p-6 capitalize">{{item.phone}}</td>
    <td class="p-6"> {{item.old_balance}}</td>
    <td class="p-6">{{item.new_balance}}</td>
    <td class="p-6">{{item.amount}}</td>
    <td class="p-6">{{item.currency}}</td>
    <td class="p-6">{{item.reference}}</td>
    <td class="p-6 capitalize">{{item.added_by}}</td>
    <td class="p-6">{{item.country}}</td>
    <td class="p-6 capitalize">{{item.created_at}}</td>
  </tr>
    </table>
</div>

      </div>

    </div>
</template>

<script>

import {mapGetters} from "vuex";

export default {
   name: "TotalRetailersPurchase",
  created() {
    this.$store.dispatch('admins/action_retailer_purchase_logs',{
      toast:this.$toast,
      router:this.$router
    });

  },
  components:{

   },
  computed:{
    ...mapGetters({
      retailer_purchase_list: 'admins/retailer_purchase_list'
    }),
  },
  //
}
</script>

<style>

</style>
