<template>
  <!-- This example requires Tailwind CSS v2.0+ -->
  <div class="fixed z-10 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
    <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
      <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>

      <!-- This element is to trick the browser into centering the modal contents. -->
      <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
      <div class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all w-1/2 align-middle my-8 mb-50 ">
        <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">

          <div class="flex flex-col bg-white rounded p-6">
            <div class="flex items-center justify-center space-x-4 mb-10">
              <h3 class="text-lg leading-6 font-medium text-expressgray" id="modal-title"> Delete Customer Account  </h3>
            </div>

            <div class="flex  items-center justify-between mt-4 ">
              <p class="inline w-1/3">Customer ID</p>
              <div class="flex space-x-2 w-2/3 justify-between">
                <input  type="text" class="border-2 rounded p-3 w-full" v-model.trim="form.id" autocomplete="ID" disabled/>
              </div>
            </div>

            <div class="flex  items-center justify-between mt-4 ">
              <p class="inline w-1/3">Customer Email</p>
              <div class="flex space-x-2 w-2/3 justify-between">
                <input  type="text" class="border-2 rounded p-3 w-full" v-model.trim="form.email" autocomplete="email" disabled/>
              </div>
            </div>

            <div class="flex  items-center justify-between mt-4 ">
              <p class="inline w-1/3">Customer Phone</p>
              <div class="flex space-x-2 w-2/3 justify-between">
                <input  type="text" class="border-2 rounded p-3 w-full" v-model.trim="form.phone"  placeholder="e.g 234XXXXXXXXXX"  disabled/>
              </div>
            </div>

            <div class="flex  items-center justify-between mt-4 ">
              <p class="inline w-1/3">Customer Name</p>
              <div class="flex space-x-2 w-2/3 justify-between">
                <input  type="text" class="border-2 rounded p-3 w-full" v-model.trim="form.name" placeholder="Customer Name" disabled/>
              </div>
            </div>







            <div class="flex  items-center justify-between mt-10 mb-5 bg-gray-50 ">
              <button type="button" class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm" @click="completeTransaction_delete_customer_account">Delete Customer Account </button>
              <button type="button" class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm" @click="cancelTransaction_delete_customer_account">Close</button>
            </div>




          </div>




        </div>

      </div>
    </div>
  </div>
</template>

<script>



export default {
  name: "add_retailer_model",
  props: [
    "selected_customer"
  ],
  created(){
    this.form.id = this.selected_customer.id
    this.form.phone  = this.selected_customer.phone
    this.form.email = this.selected_customer.email
    this.form.name = this.selected_customer.name
  },
  emits:["completeTransaction_delete_customer_account","cancelTransaction_delete_customer_account"],
  data(){
    return{
      states_option:[],
      zones_option:[],
      states:{
        Nigeria: [
          "Abia",
          "Adamawa",
          "Akwa Ibom",
          "Anambra",
          "Bauchi",
          "Bayelsa",
          "Benue",
          "Borno",
          "Cross River",
          "Delta",
          "Ebonyi",
          "Edo",
          "Ekiti",
          "Enugu",
          "FCT - Abuja",
          "Gombe",
          "Imo",
          "Jigawa",
          "Kaduna",
          "Kano",
          "Katsina",
          "Kebbi",
          "Kogi",
          "Kwara",
          "Lagos",
          "Nasarawa",
          "Niger",
          "Ogun",
          "Ondo",
          "Osun",
          "Oyo",
          "Plateau",
          "Rivers",
          "Sokoto",
          "Taraba",
          "Yobe",
          "Zamfara"
        ],
        Ghana:[
            "Accra",
            "Kumasi",
            "Tamale",
            "Sekondi",
            "Obuase",
            "Tema",
            "Cape Coast",
            "Koforidua",
            "Ho",
            "Wa",
            "Bawku",
            "Sunyani",
            "Bolgatanga",
            "Aflao",
            "Nkawkaw",
            "Hohoe",
            "Winneba",
            "Berekum",
            "Techiman",
            "Sefwi Wiawso",
            "Goaso",
            "Dambai",
            "Nalerigu",
            "Damongo"
        ]
      },
      zones:{
        Nigeria:[
            "Unknown",
          "Abeokuta North",
          "Abeokuta South",
          "Abule Egba",
          "ABULOMA",
          "ADO RD",
          "Agege",
          "AGO PALACE",
          "AGUDA -SURULERE",
          "AGURA",
          "AirportRd",
          "AJAH",
          "Ajao",
          "AKARIGBO",
          "Akoka",
          "Alapere",
          "Alausa",
          "ALLEN AVENUE",
          "Amuwo-Odofin",
          "Amuwo-Odofin Festac",
          "Anthony",
          "Arepo",
          "AWOYAYA",
          "Badore",
          "BENIN ZONE",
          "Berger",
          "Bodethomas",
          "DLINE",
          "Eastern Bypass",
          "EDO SCHOOL PROJECT",
          "EGBEDA",
          "Eleganza",
          "Eti-Osa",
          "FADEYI",
          "Gbagada",
          "GRA,SABO",
          "Ifako-Ijaiye",
          "IJESHA",
          "Ikeja",
          "Ikorodu",
          "IKOSI KETU",
          "IKOTA",
          "ILUPEJU",
          "ISHERI",
          "Isola",
          "IWAYA",
          "Iyana-Ipaja",
          "Jakande",
          "Kosofe",
          "Lagos Island",
          "Lagos Mainland",
          "lawanson",
          "Mafolukun-oshodi",
          "Magboro",
          "MAGODO",
          "Maruwa",
          "MARYLAND",
          "MILE12",
          "Mushin",
          "OGBA",
          "Ogbunabali",
          "Ogudu",
          "Ojodu Begger",
          "OJOTA",
          "OKOTA",
          "OLUSOSUN",
          "Opebi",
          "Oregun",
          "Orile",
          "Oshodi",
          "OWORO",
          "PAPA AJAO",
          "Rumuogba",
          "Yaba",
          "Satellite",
          "Shagamu",
          "Shomolu",
          "Shongotedo",
          "Surulere",
          "TRANS AMADI",
          "Westwood",
          "Woji"
        ],
        Ghana:[
          "Adenta",
          "Botwe",
          "East Legon",
          "Ivy",
          "kaiser",
          "lapaz",
          "Madina",
          "menpasem",
          "SPINTEX"

        ]
      },
      form:{
        email:null,
        password:"admin",
        prefix:"hotspotxwf_nigeria_",
        phone:null,
        name: "",
        type:'customer',
        country:""
      },


    }
  },
  watch:{
    'form.country' : {
      handler(newVal, oldVal){  // here having access to the new and old value
        // do stuff
        // console.log("country", newVal)
        if(newVal==="Nigeria"){
          this.states_option = this.states.Nigeria
          this.zones_option = this.zones.Nigeria
        }else{
          this.states_option = this.states.Ghana
          this.zones_option = this.zones.Ghana
        }
      },
      deep: true,
      immediate: true
    }
  },




  methods: {
    emailValidate : function (email) {
      const regexp= /^[\w.%+-]+@[\w.-]+\.[\w]{2,6}$/;
      return regexp.test(email);
    },
    phoneValidate: function (phone){
      const regexp= /234[0-9]{10}/;
      const regexp_ghana =  /233[0-9]{9}/;
      return regexp.test(phone) || regexp_ghana.test(phone) ;
    },
    resetForm: function(){
      for (var item in this.form)
      {
        this.form[item] = null
      }
},
    completeTransaction_delete_customer_account()  {
      // this.form.city = this.form.zone

        this.$emit('completeTransaction_delete_customer_account', [this.form])

    },
    cancelTransaction_delete_customer_account: function (e) {
      this.$emit('cancelTransaction_delete_customer_account')
      this.resetForm()
    }
  }


}
</script>

<style scoped>

</style>
