export default {
    setIssues(state,payload){
        state.issues = payload
    },
    setTopicUid(state,payload){
        state.topic_uid = payload
    },
    setSpecificIssue(state,payload){
        state.specific_issue = payload
    },
    setComments(state,payload){
        state.comments = payload
    }
}