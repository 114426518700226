<template>
  <div class="bg-white rounded-2xl flex flex-col text-expressgray shadow-2xl p-6">
    <h1 class="text-4xl">Sales Report</h1>

    <div class="flex flex-col mt-10 ">
      <h3 class="text-center mt-3  text-3xl"> Monthly Sales Volume (NGN)</h3>
      <line-chart :data="{'2021-11-01': 15000, '2021-12-01': 19000 , '2022-01-01': 13000, '2022-02-01': 11000, '2022-03-01': 14200}"></line-chart>

    </div>


    <div class="flex flex-col mt-10 ">
      <div class="flex flex-col mt-20">
        <h3 class="text-center mt-3 text-3xl"> Data-Plan Breakdown (%)</h3>
        <pie-chart :data="[['Daily Plan 100MB', 10], ['Daily Plan 300MB', 30],['Daily Plan 750MB', 20],['Monthly Plan 5GB', 30],['Monthly Plan 10GB', 10]]" ></pie-chart>

      </div>


      <div class="flex flex-col flex-1 mt-10">
        <div class="flex flex-col mt-20">
          <h3 class="text-center mt-3 text-3xl"> Weekly Customer Sales Report (NGN)</h3>
          <column-chart :colors="['#16a34a']" :data="[['Sun', 1500], ['Mon', 4000], ['Tue', 9000],['Wed', 7000], ['Thur', 6500], ['Fri', 7000], ['Sat', 3000]]"></column-chart>
        </div>

        <div class="flex flex-col mt-20">
          <h3 class="text-center mt-3 text-3xl"> Weekly Retailer Recharge Report (NGN)</h3>
          <column-chart :colors="['#16a34a']"  :data="[['Sun', 0], ['Mon', 5000], ['Tue', 0],['Wed', 0], ['Thur', 10000], ['Fri', 0], ['Sat', 0]]"></column-chart>
        </div>

      </div>

    </div>



  </div>
</template>

<script>
export default {
  name: "SalesReport"
}
</script>

<style scoped>

</style>
