<template>
    <div class="mt-4 h-full w-full">
      <div  class="text-expressgray w-screen overflow-x-scroll px-5 bg-white">
        <tabs  :options="{ useUrlFragment: false }" @clicked="tabClicked" @changed="tabChanged" nav-item-class="nav-item" class="overflow-x-scroll">

          <tab name="Growth & Analytics" class="mt-5 h-full">
            <div class="h-full w-full">
                <admin_analytics></admin_analytics>
           </div>
          </tab>


          <tab name="Retailers (Nigeria)" class="mt-5 h-full">
            <div class="h-full">
              <retailertable  country="Nigeria"/>

            </div>
          </tab>

<!--          <tab name="Retailers (Ghana)" class="mt-5  h-full">-->
<!--            <div class="h-full">-->
<!--              <retailertable country="Ghana"/>-->

<!--            </div>-->
<!--          </tab>-->


<!--          <tab name="Field Officers & Administrators" class="mt-5  h-full">-->
<!--            <div class="h-full">-->
<!--              <div class="h-16  my-5 w-full  flex items-center p-10 bg-white  mt-10 border-2 rounded hover:shadow-2xl hover:bg-gray-400 hover:text-white">-->
<!--                <div class="h2  cursor-pointer  w-full p-5 "  @click="showRDE"> <i class="fas fa-plus"></i> Add New RDE & Administrator </div>-->
<!--              </div>-->

<!--&lt;!&ndash;              "completeRDEAccount","cancelRDEAccount"&ndash;&gt;-->

<!--              <div v-if="show_add_rde">-->
<!--                <add_staff_model-->
<!--                 @completeRDEAccount="completeRDEAccount"-->
<!--                 @cancelRDEAccount="cancelRDEAccount"-->
<!--                />-->
<!--              </div>-->
<!--              <rdetable/>-->
<!--            </div>-->
<!--          </tab>-->
<!--          <tab name="Hotspot Customers" class="mt-5   h-full">-->
<!--            <div class="h-full flex flex-col">-->


<!--              <div class="h-16  my-1 w-full p-5 rounded flex flex-col">-->



<!--                <userlist-table :rowRaws="customer_list"/>-->
<!--                <pagination :total-pages="totalPages"-->
<!--                            :total="total"-->
<!--                            :per-page="perPage"-->
<!--                            :current-page="currentPage"-->
<!--                            :has-more-pages="hasMorePages"-->
<!--                            @pagechanged="showMore">-->
<!--                </pagination>-->

<!--                </div>-->





<!--             </div>-->
<!--          </tab>-->

        </tabs>
      </div>



    </div>
</template>

<script>
import retailerstable from "@/views/layouts/rde/rdeuicomponent/retailerstable";
import rdetable from "@/views/layouts/rde/rdeuicomponent/rdetable";
import add_staff_model from "@/components/uicomponents/models/add_staff_model";
// import usersTables from "@/components/uicomponents/tables/UsersTables";
import usersListTable from "@/components/uicomponents/tables/UsersListTable";
import { mapGetters } from 'vuex'
import analyticsContainer from "@/views/layouts/rde/rdeuicomponent/analyticsContainer";
export default {
  name: "RdeMain",
  created() {
    //
    this.$store.dispatch('customers/action_customer_list',{
      page_no: this.currentPage,
      perPage: this.perPage,
      toast:this.$toast,
      router:this.$router
    });


  },
  data(){
    return {
      show_add_rde: false,
      currentPage:1,
      perPage: 10,
      totalPages: 1,
      total:10,
      hasMorePages: null
    }
  },
  computed:{
    ...mapGetters({
      customer_list: 'customers/customer_list'
    }),
  },
  components:{
    "retailertable":retailerstable,
   // "rdetable":rdetable,
    // "userstable":usersTables,
    // "userlist-table":usersListTable,
    "admin_analytics":analyticsContainer,
    // "add_staff_model": add_staff_model
  },
  methods:{
    showMore(data){
     this.currentPage = data
    },
    showRDE(){
      this.show_add_rde = !this.show_add_rde
    },
    cancelRDEAccount(){
      this.show_add_rde = false
    },
    completeRDEAccount(data){
      console.log("completedRDEAccount data",data)

      this.$store.dispatch('authentication/rde_register',{
        email: data[0].email,
        password:  data[0].password,
        phone: data[0].phone,
        name: data[0].name,
        country:  data[0].country,
        toast:this.$toast,
        router:this.$router
      });

      this.show_add_rde = false
    }
  }
}
</script>

