<template>
    <div class="flex flex-col w-full p-3">
      <div class="h-20 bg-white flex  justify-between items-center space-x-4 mb-10 p-1">

        <div class="flex flex-col space-y-2">
          <h1 class="text-express font-bold text-2xl font-serif">Call Card</h1>
          <p>A call card allows you to create schedule for RDE’s on a weekly basis, by adding retailer and location to an RDE journey plan.</p>
        </div>

        <button class="text-xs rounded text-right w-52 border-2 md:px-5 px-2 flex py-2 items-center space-x-1 hover:text-white hover:bg-gray-600">
          <i class="fas fa-plus-circle mr-1"></i>
          New Team
        </button>
      </div>


      <div  class="flex flex-col xl:flex-row w-full bg-white rounded xl:space-y-0 space-y-5 p-1 mt-5 xl:space-x-4 ">
        <div class="xl:w-1/2 w-full flex flex-col space-y-5">
          <div class="flex justify-between items-center">
            <h1>Starting Date</h1>
            <Datepicker/>
          </div>

          <div class="flex justify-between items-center">
            <h1>End Date</h1>
            <Datepicker/>
          </div>

          <div class="flex justify-between items-center">
            <select class="w-full border-2 p-2 rounded" name=""  >
              <option value="">Select Team</option>
              <option value="">Team Williams</option>
              <option value="">Team Amadi</option>
              <option value="">Team Lawal</option>
            </select>
          </div>

        </div>

        <div class="xl:w-1/2 w-full flex flex-col space-y-5">
          <div class="flex justify-between items-center">
            <h1>Choose Location</h1>
            <Datepicker/>
          </div>

          <div class="flex justify-between items-center">
            <select class="w-full border-2 p-2 rounded" name="" id="" >
              <option value="">Select Zone</option>
              <option value="">Ojota</option>
              <option value="">Agege</option>
              <option value="">Lekki</option>
            </select>
          </div>

          <div class="flex  items-center space-x-4">
            <h1>Card Name</h1>
            <input type="text"  value="Team Williams 17 Jan" disabled class=" border-2 rounded p-2">
          </div>
        </div>

      </div>


      <div  class="w-full  rounded p-1 mt-5  flex flex-col xl:space-x-4 ">
        <div class="h-20 p-3 flex justify-between items-center bg-inboxbg">
            <h2>Monday (24/01/2022) <span>  <i class="fas fa-trash-alt text-red-500 ml-3 cursor-pointer"></i> </span> </h2>

          <button class="border-2 rounded sm:px-6 sm:py-3 px-4 hover:text-white hover:bg-gray-500"> Add Next Day</button>
        </div>

        <div  class="flex flex-col xl:flex-row w-full bg-white rounded p-1 mt-5 xl:space-y-0 space-y-5 xl:space-x-4 ">
          <div class="xl:w-1/2 w-full flex flex-col space-y-5 ">
            <h1 class="text-lg font-bold font-serif"><span><i class="fas fa-check-circle text-green-500 mr-3"></i></span> Choose Personnel</h1>
            <div class="flex items-center space-x-4  w-full" >
              <h1 class="">Slot 1</h1>
              <input type="text" value="Abdulrauf Kabir" class="flex-1 border-2 rounded p-3 " placeholder="Select RDE Personnel">
            </div>

            <div class="flex items-center space-x-4  w-full" >
              <h1 class="">Slot 2</h1>
              <input type="text" value="Badaru Shefiu" class="flex-1 border-2 rounded p-3 " placeholder="Select RDE Personnel">
            </div>

            <div class="flex items-center space-x-4  w-full" >
              <h1 class="">Slot 3</h1>
              <input type="text" value="Ogunkola Emmanuel" class="flex-1 border-2 rounded p-3 " placeholder="Select RDE Personnel">
            </div>

            <div class="flex items-center space-x-4  w-full" >
              <h1 class="">Slot 4</h1>
              <input type="text" value="Emeka Ibong" class="flex-1 border-2 rounded p-3 " placeholder="Select RDE Personnel">
            </div>

            <div class="flex items-center space-x-4  w-full" >
              <h1 class="">Slot 5</h1>
              <input type="text" value="Chukwudi Charles" class="flex-1 border-2 rounded p-3 " placeholder="Select RDE Personnel">
            </div>

          </div>



          <div class="xl:w-1/2 w-full flex flex-col space-y-5 ">
            <h1 class="text-lg font-bold font-serif"><span><i class="fas fa-check-circle text-green-500 mr-3"></i></span> Choose Retailer</h1>
            <div class="flex items-center space-x-4  w-full" >
              <h1 class="">Slot 1</h1>
              <input type="text" value="Try and See Technologies" class="flex-1 border-2 rounded p-3 " placeholder="Select Retailer Personnel">
            </div>

            <div class="flex items-center space-x-4  w-full" >
              <h1 class="">Slot 2</h1>
              <input type="text" value="Emmacep Concept" class="flex-1 border-2 rounded p-3 " placeholder="Select RDE Personnel">
            </div>

            <div class="flex items-center space-x-4  w-full" >
              <h1 class="">Slot 3</h1>
              <input type="text" value="Kenzo Unisex Saloon" class="flex-1 border-2 rounded p-3 " placeholder="Select RDE Personnel">
            </div>

            <div class="flex items-center space-x-4  w-full" >
              <h1 class="">Slot 4</h1>
              <input type="text" value="Monik catering services" class="flex-1 border-2 rounded p-3 " placeholder="Select RDE Personnel">
            </div>

            <div class="flex items-center space-x-4  w-full" >
              <h1 class="">Slot 5</h1>
              <input type="text" value="Standon ventures" class="flex-1 border-2 rounded p-3 " placeholder="Select RDE Personnel">
            </div>

          </div>

        </div>



        <div class="flex flex-col mt-5">

          <div class="h-20 p-1 flex justify-between items-center bg-inboxbg">
            <h2>Tuesday (25/01/2022) <span>  <i class="fas fa-trash-alt text-red-500 ml-3 cursor-pointer"></i> </span> </h2>

            <button class="border-2 rounded sm:px-6 sm:py-3 px-4 hover:text-white hover:bg-gray-500"> Add Next Day</button>
          </div>


          <div  class="flex flex-col xl:flex-row w-full bg-white rounded p-1 mt-5 xl:space-y-0 space-y-5 xl:space-x-4 ">

            <div class="xl:w-1/2 w-full flex flex-col space-y-5 ">
              <h1 class="text-lg font-bold font-serif"><span><i class="fas fa-check-circle text-green-500 mr-3"></i></span> Choose Personnel</h1>
              <div class="flex items-center space-x-4  w-full" >
                <h1 class="">Slot 1</h1>
                <input type="text" value="Choose Personnel" class="flex-1 border-2 rounded p-3 " placeholder="Select RDE Personnel">
              </div>

              <div class="flex items-center space-x-4  w-full" >
                <h1 class="">Slot 2</h1>
                <input type="text" value="Choose Personnel" class="flex-1 border-2 rounded p-3 " placeholder="Select RDE Personnel">
              </div>

              <div class="flex items-center space-x-4  w-full" >
                <h1 class="">Slot 3</h1>
                <input type="text" value="Choose Personnel" class="flex-1 border-2 rounded p-3 " placeholder="Select RDE Personnel">
              </div>

              <div class="flex items-center space-x-4  w-full" >
                <h1 class="">Slot 4</h1>
                <input type="text" value="Choose Personnel" class="flex-1 border-2 rounded p-3 " placeholder="Select RDE Personnel">
              </div>

              <div class="flex items-center space-x-4  w-full" >
                <h1 class="">Slot 5</h1>
                <input type="text" value="Choose Personnel" class="flex-1 border-2 rounded p-3 " placeholder="Select RDE Personnel">
              </div>

            </div>



            <div class="xl:w-1/2 w-full flex flex-col space-y-5 ">
              <h1 class="text-lg font-bold font-serif"><span><i class="fas fa-check-circle text-green-500 mr-3"></i></span> Choose Retailer</h1>
              <div class="flex items-center space-x-4  w-full" >
                <h1 class="">Slot 1</h1>
                <input type="text" value="Select retailer" class="flex-1 border-2 rounded p-3 " placeholder="Select Retailer Personnel">
              </div>

              <div class="flex items-center space-x-4  w-full" >
                <h1 class="">Slot 2</h1>
                <input type="text" value="Select retailer" class="flex-1 border-2 rounded p-3 " placeholder="Select RDE Personnel">
              </div>

              <div class="flex items-center space-x-4  w-full" >
                <h1 class="">Slot 3</h1>
                <input type="text" value="Select retailer" class="flex-1 border-2 rounded p-3 " placeholder="Select RDE Personnel">
              </div>

              <div class="flex items-center space-x-4  w-full" >
                <h1 class="">Slot 4</h1>
                <input type="text" value="Select retailer" class="flex-1 border-2 rounded p-3 " placeholder="Select RDE Personnel">
              </div>

              <div class="flex items-center space-x-4  w-full" >
                <h1 class="">Slot 5</h1>
                <input type="text" value="Select retailer" class="flex-1 border-2 rounded p-3 " placeholder="Select RDE Personnel">
              </div>

            </div>

          </div>



          <div class="flex flex-col justify-center items-center p-5 space-y-4">
            <button  class="bg-red-500 rounded text-center   items-center px-6 py-3 w-1/2 text-white">
              Preview
            </button>

            <button  class="bg-green-500 rounded text-center   items-center px-6 py-3 w-1/2 text-white">
              Deploy
            </button>

          </div>

        </div>




      </div>

    </div>
</template>

<script>
export default {
  name: "createcallContainer"
}
</script>

<style scoped>

</style>
