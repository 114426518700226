<template>
  <div class="overflow-auto p-1 flex flex-col text-expressgray">
     <div class="flex flex-col items-center md:items-stretch md:flex-row w-full xl:space-x-4 space-y-5 md:space-y-0">
          <div class="md:w-2/3 w-full sm:py-0 py-14 p-2 rounded  bg-white shadow-lg">
             <rylayout/>
          </div>

          <div class="md:w-1/3 w-full  rounded  bg-white shadow-lg">
            <rxlayout/>
          </div>
     </div>


    </div>
</template>

<script>
import rde_layoutContainer from "@/views/layouts/rde/rdeuicomponent/rde_layoutContainer";
import createcallContainer from "@/views/layouts/rde/rdeuicomponent/createcallContainer";
export default {

  name: "CreateCallCard",
  components:{
     'rxlayout':rde_layoutContainer,
     'rylayout':createcallContainer
  },

}
</script>

<style scoped>

</style>
