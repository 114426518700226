<template>
  <div class="flex flex-col p-5">
    <p class="text-2xl text-expressgray text-center lg:text-left lg:pl-10 font-semibold font-serif">Retailer Overview</p>
    <div class="flex flex-row space-x-5 bg-white text-expressgray justify-between p-5 mt-2 rounded-2xl shadow-2xl">
      <summarycard
          imageurl="https://res.cloudinary.com/dcsensx8u/image/upload/v1643862532/Screenshot_2022-02-03_at_5.28.45_AM.png"
          :title="customers_total"
          label="Customer(s)"
      />
      <summarycard
          imageurl="https://res.cloudinary.com/dcsensx8u/image/upload/v1643860970/Screenshot_2022-02-03_at_5.02.43_AM.png"
          :title="wallet_balance"
          label="Wallet Balance"
      />

      <summarycard
          imageurl="https://res.cloudinary.com/dcsensx8u/image/upload/v1643891658/Screenshot_2022-02-03_at_1.34.12_PM.png"
          :title="sales_volume"
          label="Lifetime Sales"/>
    </div>

<!--    Retailere-->



      <div class="grid grid-cols-1 xl:grid-cols-1 gap-5 mt-2 ">
        <div class="bg-white text-expressgray p-5 rounded-2xl shadow-2xl">
            <div class="flex flex-col p-5">
              <p class="text-lg text-expressgray text-center md:text-left font-semibold font-serif">  My Information</p>
              <div class="grid grid-col-1 md:grid-cols-2 text-center md:text-left gap-8 mt-5 mb-5">
                <titlecard title="Business Name" :description="user.business_name" colour="text-expressgray"/>
                <titlecard title="Email" :description="user.email" colour="text-red-400"/>

                <titlecard title="Business Address" :description="user.address" colour="text-expressgray"/>
                <titlecard title="Phone Number" :description="user.phone" colour="text-expressgray"/>


                <titlecard title="Activation Date" :description="getHumanDate(user.created_at)" colour="text-expressgray"/>
                <titlecard title="Last Updated" :description="getHumanDate(user.updated_at)" colour="text-express"/>

              </div>
            </div>
        </div>

<!--        <div class="bg-white text-expressgray p-5 rounded-2xl shadow-2xl">-->
<!--          <div class="flex flex-col p-5">-->
<!--            <p class="text-lg text-expressgray text-center md:text-left font-semibold font-serif">  Assigned RDE Personnel</p>-->
<!--            <div class="grid grid-col-1 md:grid-cols-2 text-center md:text-left gap-8 mt-5 mb-5">-->
<!--              <titlecard title="RDE Name" description="N/A" colour="text-expressgray"/>-->
<!--              <titlecard title="Email" description="N/A" colour="text-red-400"/>-->

<!--              <titlecard title="RDE Email" description="N/A" colour="text-express"/>-->
<!--              <titlecard title="" description="" colour=""/>-->

<!--              <titlecard title="Last Updated" description="N/A" colour="text-express"/>-->
<!--              <titlecard title="" description="" colour=""/>-->


<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
      </div>








  </div>
</template>

<script>
import moment from 'moment'
import summarycard from "@/components/uicomponents/cards/summarycard";
import TitleContentCard from "@/components/uicomponents/cards/TitleContentCard";
import { mapGetters } from 'vuex'

// import TitleContentCardBasestation from "@/components/uicomponents/cards/TitleContentCardBasestation";
// import TitleContentCardStatus from "@/components/uicomponents/cards/TitleContentCardStatus";
export default {
  name: "MainOverview.vue",
   created() {
      //clear all token and logs
      //Total Retailer sales
      this.$store.dispatch('retailers/total_retailer_sales',{
            toast:this.$toast,
            router:this.$router
          });

      // total retailer wallet balance
      this.$store.dispatch('retailers/action_retailer_wallet_balance',{
         toast:this.$toast,
         router:this.$router
      });

      // total lifetime sales value
      this.$store.dispatch('retailers/action_retailer_lifetime_sales_value',{
         toast:this.$toast,
         router:this.$router
      });



console.log("user", this.user)

    },
  components:{
    'summarycard': summarycard,
    'titlecard': TitleContentCard,
    // 'titlecardbasestation': TitleContentCardBasestation,
    // 'titlecardstatus':TitleContentCardStatus
  },
    computed:{
        ...mapGetters({
          customers_total :'retailers/customers_total',
          wallet_balance: 'retailers/wallet_balance',
          sales_volume: 'retailers/sales_volume',
          user: 'authentication/getUser'
        }),
        //  timestamp: function (date) {
        //   return moment(date.format('YYYY-MM-DD [at] hh:mm'))
  },
       methods: {
            getHumanDate : function (date) {
                return moment(date, 'YYYY-MM-DD').format('Do MMMM,YYYY');
            }
        }
}
</script>

<style scoped>

</style>
