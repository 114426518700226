<template>
  <div class="w-screen">
    <div class="nav h-20 flex flex-row justify-between items-center p-2 w-full">
      <div class="flex lg:space-x-5 space-x-1 items-center justify-center">
        <img class="lg:ml-2 h-16 hidden lg:flex my-2 lg:w-36 w-24  h-16 my-2 p-3 " src="https://res.cloudinary.com/dcsensx8u/image/upload/v1669473267/Screenshot_2022-11-26_at_2.34.23_PM.png" alt="Tizeti Hotspot Logo">
<!--        <img class="lg:ml-2 h-16 hidden lg:flex my-2 lg:w-36 w-24" src="https://res.cloudinary.com/dcsensx8u/image/upload/v1644320869/Hotspot_logo_5.png" alt="wificomlogo">-->
        <div  class="relative border-2 border-express rounded-2xl sm:w-96 w-56">
          <input
              class="border-0 rounded-2xl w-11/12 px-2 py-1 focus:outline-none"
              type="text"
              placeholder="Search Retailer"
              v-model.trim="searchvalue">

          <button @click="navigate(`/search/${searchvalue}`)">
           <svg class=" sm:w-5 sm:h-5 w-4 h-4 inset-center text-expressgray cursor-pointer " fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clip-rule="evenodd"></path></svg>
          </button>




        </div>


      </div>


      <div class="flex flex-col lg:flex-row lg:space-x-3 justify-between cursor-pointer">
<!--        <i class="far fa-user text-sm hover:text-express text-center h-full "></i>-->
        <div class="flex justify-end items-end text-sm space-x-3">
          <button v-if="user.primaryaccount==='super_administrator'  " class="px-2 py-1 bg-green-700 rounded lg:flex text-xl text-sm text-white"><a href="https://legacy.tizeti.com/hotspot/download_eutelsat_retailers.php">Download All Retailers  </a> </button>
          <button v-if="user.primaryaccount==='super_administrator'  " class="px-2 py-1 bg-green-700 rounded lg:flex text-xl text-sm text-white"><a href="https://legacy.tizeti.com/hotspot/download_eutelsat_customers.php">Download All Customers  </a> </button>
<!--          <p class="text-red-600 sm:text-sm text-xs">Options</p>-->
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "AuthHeaders.vue",
  created() {
    console.log('user',this.user)
  },
  data(){
    return {
      darkmode:false,
      searchvalue: ''
    }
  },
  computed:{
    ...mapGetters({
      user :'authentication/getUser',
    })
  },
  methods:{
    toggle:(value)=>{
      console.log("Parent Component received event ", value)
  },
   navigate:  function(url){
     // url = `${url}?${this.searchvalue}`
    // console.log('url',url)
      this.$store.dispatch('admins/action_search_personnel',{
       search_value: this.searchvalue,
       toast:this.$toast,
       router:this.$router
     });



     this.$router.push({path: url, query: { search_value: this.search_value}})
      // this.$router.push(url)
    }
  }
}
</script>

<style scoped>

.inset-center {
  position: absolute;
  top: 50%;
  right: 1%;
  transform: translate(-50%, -50%);
}

</style>
