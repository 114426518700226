<template>
   <div class="flex flex-col items-center justify-center h-screen">
     <h1 class="text-4xl">404</h1>
     <p>Page not found</p>
     <router-link
         to="/retailer/overview"
         class="mt-5 bg-expressbutton text-white rounded p-3"> Go Back
     </router-link>

   </div>
</template>

<script>
export default {
  name: "PageNotFound"
}
</script>

<style scoped>

</style>
