<template>
  <div class="flex flex-col p-4 w-full">
     <h1 class="text-2xl text-center lg:text-left lg:pl-10 font-semibold font-serif">Your Profile</h1>
     <div class="flex flex-col p-5 mt-2 bg-white rounded-lg shadow-lg justify-center border-2 items-center w-full">


       <form action="#" method="post" class="w-full h-full">
         <div class="flex flex-col p-5 mt-2 border-0">

           <div class="flex  flex-col lg:flex-row items-center justify-center">
             <p class="inline lg:w-1/6 lg:mr-3">Business Name</p>
             <div class="flex space-x-3 md:w-96 sm:w-80 w-72 justify-between">
               <input type="text" :value="user.business_name" class="border-2 rounded p-3 w-full mt-2" disabled >
             </div>
           </div>

           <div class="flex flex-col lg:flex-row items-center justify-center mt-4">
             <p class="inline lg:w-1/6 lg:mr-3">Full Name</p>
             <div class="flex space-x-2 md:w-96 sm:w-80 w-72 justify-between">
               <input type="text" :value="user.name" class="border-2 rounded p-3 w-full mt-2" disabled >
             </div>
           </div>

           <div class="flex flex-col lg:flex-row  items-center justify-center mt-4 ">
             <p class="inline lg:w-1/6 lg:mr-3">Email</p>
             <div class="flex space-x-2 md:w-96 sm:w-80 w-72 justify-between">
               <input type="email" :value="user.email" class="border-2 rounded p-3 w-full mt-2" disabled>
             </div>
           </div>

           <div class="flex flex-col lg:flex-row  items-center justify-center mt-4 ">
             <p class="inline lg:w-1/6 lg:mr-3">Phone Number</p>
             <div class="flex space-x-2 md:w-96 sm:w-80 w-72 justify-between">
               <input type="text" :value="user.phone" class="border-2 rounded p-3 w-full mt-2" disabled>
             </div>
           </div>

           <div class="flex flex-col lg:flex-row  items-center justify-center mt-4 ">
             <p class="inline lg:w-1/6 lg:mr-3">Address</p>
             <div class="flex space-x-2 md:w-96 sm:w-80 w-72 justify-between">

               <textarea class="border-2 rounded w-full p-3 mt-2" cols="3" rows="5" disabled :value="user.address"></textarea>
             </div>
           </div>


           <div class="flex flex-col lg:flex-row items-center justify-center mt-8 ">
             <p class="inline lg:w-1/6 lg:mr-3"></p>
             <div class="flex space-x-2 md:w-96 sm:w-80 w-72 justify-between">
                <button type="button" class="rounded border-2 p-3 hover:bg-gray-200 hover:text-black" @click="toggleModel" > Change Password </button>
               <button type="button" class=" hidden rounded border-2 p-3 bg-green-600 text-white hover:bg-green-400"  > Save Changes</button>
             </div>
           </div>


<!--           <div class="flex flex-col lg:flex-row  items-center justify-end mt-8 ">-->
<!--               <button type="button" class="rounded border-2 p-3 hover:bg-gray-200 hover:text-black"  > Save Changes</button>-->
<!--           </div>-->



         </div>
       </form>


<!--       Model -->

       <div v-if="showPasswordChange">
         <changepasswordmodals
             :phonenumber="user.phone"
             @completeTransaction=completeTransaction
             @cancelTransaction=cancelTransaction
         />
       </div>

<!--       End Model-->


     </div>






  </div>
</template>

<script>
 import changepasswordmodals from "@/components/uicomponents/models/changepasswordmodals";
import { mapGetters } from 'vuex'
export default {
  name: "Profile",
  components:{
     "changepasswordmodals":changepasswordmodals
  },
   computed:{
        ...mapGetters({
          user: 'authentication/getUser'
        }),
        //  timestamp: function (date) {
        //   return moment(date.format('YYYY-MM-DD [at] hh:mm'))
  },
  data(){
    return {
      showPasswordChange: false
    }
  },
  methods: {
    toggleModel: function(){
      this.showPasswordChange  = true
    },
    completeTransaction: function(data){

      //console.log("Profile completeTransaction triggered", data)

      // action_change_user_password
      this.$store.dispatch('authentication/action_change_user_password',{
        data: data,
        toast:this.$toast,
        router:this.$router
      });
      this.showPasswordChange = false

    },
    cancelTransaction: function(){

      this.showPasswordChange = false
    }

  }
}
</script>

<style scoped>

</style>
