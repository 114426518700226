<template>
  <!-- This example requires Tailwind CSS v2.0+ -->
  <div class="fixed z-10 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
    <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
      <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>

      <!-- This element is to trick the browser into centering the modal contents. -->
      <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
      <div class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all w-1/2 align-middle my-8 mb-50 ">
        <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">

          <div class="flex flex-col bg-white rounded p-6">
            <div class="flex items-center justify-center space-x-4 mb-10">
              <h3 class="text-lg leading-6 font-medium text-expressgray" id="modal-title"> Move Retailer to Decommission List </h3>
            </div>



            <div class="flex  items-center justify-between mt-4 ">
              <p class="inline w-1/3">Retailer Phone</p>
              <div class="flex space-x-2 w-2/3 justify-between">
                <input  type="text" class="border-2 rounded p-3 w-full" v-model.trim="form.phone" disabled/>
              </div>
            </div>

            <div class="flex  items-center justify-between mt-4 ">
              <p class="inline w-1/3">Retailer Name</p>
              <div class="flex space-x-2 w-2/3 justify-between">
                <input  type="text" class="border-2 rounded p-3 w-full" v-model.trim="form.name" placeholder="Full Name" disabled/>
              </div>
            </div>


            <div class="flex  items-center justify-between mt-4 ">
              <p class="inline w-1/3">Extra-Details</p>
              <div class="flex space-x-2 w-2/3 justify-between">
                <textarea v-model.trim="form.reason"  class="border-2 rounded p-3 w-full" cols="30" rows="10"></textarea>
              </div>
            </div>

            <div class="flex  items-center justify-between mt-4 ">
              <p class="inline w-1/3">Decommissioned Status</p>
              <div class="flex space-x-2 w-2/3 justify-between">
                <select v-model.trim="form.status" class="border-2 rounded p-3 w-full">
                  <option value="pending">Pending ( Move Retailer to Decommission List, Handled by RDE) </option>
                  <option value="completed">Completed (All Devices Retrieved and Process Completed) </option>
                </select>
              </div>
            </div>



            <div class="flex  items-center justify-between mt-4 ">
              <p class="inline w-1/3">Assigned to RDE</p>
              <div class="flex space-x-2 w-2/3 justify-between">
                <select v-model="form.rde_personnel" class="border-2 rounded p-3 w-full">
                  <option v-for="(item,index) in active_rde_list" v-bind:value="item.name" :key="index">
                    {{ item.name }} ( {{ item.email}})
                  </option>
                </select>
              </div>
            </div>


            <div class="flex  items-center justify-between mt-10 mb-5 bg-gray-50 ">
              <button type="button" class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm" @click="completeDecommissionTransaction">Move Retailer to Decommission List</button>
              <button type="button" class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm" @click="cancelDecommissionTransaction">Close</button>
            </div>




          </div>




        </div>

      </div>
    </div>
  </div>
</template>

<script>



import {mapGetters} from "vuex";

export default {
  name: "add_retailer_to_decommission",
  props: ['retailer_id','name','phone'],
  emits:["completeDecommissionTransaction","cancelDecommissionTransaction"],
  created() {

    //get the list of retailers


    this.$store.dispatch('admins/action_rde_list',{
      page: 1,
      perPage: 1000,
      country: this.country,
      toast:this.$toast,
      router:this.$router
    });

    let temp = this.rde_list.data.filter(item => item.status === "Active" && item.type === "RDE")


    this.active_rde_list =  temp



    this.form.retailer_id = this.retailer_id
    this.form.name = this.name
    this.form.phone = this.phone
  },
  data(){
    return{
      active_rde_list:[],
      form:{
        name:null,
        phone:null,
        retailer_id:null,
        reason: null,
        rde_personnel:null,
        status: "pending",
      },
    }
  },
  computed: {
    ...mapGetters({
      rde_list: 'admins/rde_list',
      getUser: 'authentication/getUser'

    }),
  },
  watch:{

  },




  methods: {
    resetForm: function(){
      for (var item in this.form)
      {
        this.form[item] = null
      }
},
    completeDecommissionTransaction()  {
      console.log("this.form",this.form)
      // this.form.city = this.form.zone
      if( (this.form.rde_personnel  !== null )  ){
        this.$emit('completeDecommissionTransaction', this.form)
      }
      else{
          let fields = ''
           fields = fields+' Select  an RDE'
          this.$toast.error(`Error Missing fields:  ${fields}`)
      }
    },
    cancelDecommissionTransaction: function (e) {
      this.$emit('cancelDecommissionTransaction')
      this.resetForm()
    }
  }


}
</script>

<style scoped>

</style>
