<template>
  <div class="flex flex-col items-stretch">
    <div class="flex space-x-4 justify-between p-5 items-center">
      <h1> Field Officers & Administrators -  {{ total_rde }} </h1>
      <div class="flex space-x-4">
        <button class="rounded lg:px-6 px-4 md:py-3 py-1 border-2 flex justify-evenly items-center space-x-3 sm:text-base text-xs" @click="download_csv"> <i class="fas fa-cloud-download-alt mr-2"></i> Download </button>
      </div>
    </div>
    <div class="flex w-full flex-col mt-4">

      <div v-if="isLoading">
        <loading message="Loading, please wait"></loading>
      </div>
      <div v-else>
        <rde-list-table :rowRaws="rdes"/>
      </div>
    </div>

    <div class="flex justify-between bg-white" >
      <div class="flex justify-between p-2 mx-3 bg-white w-64 items-center justify-center">
        <p>Records Per-Page</p>
        <select v-model="perPage">
          <option value="10">10</option>
          <option value="50">50</option>
          <option value="100">100</option>
          <option value="100">500</option>
          <option value="100">1000</option>
        </select>
      </div>

<!--      <rdes/>-->
    <pagination :total-pages="totalPages"
                :total="total"
                :per-page="perPage"
                :current-page="currentPage"
                :has-more-pages="hasMorePages"
                @pagechanged="showMore">
    </pagination>
    </div>

  </div>




</template>

<script>

import RDEListTable from "@/components/uicomponents/tables/RDEListTable";
import {mapGetters} from "vuex";
export default {
  name: "rdetable",
  components:{
    "rde-list-table":RDEListTable
  },
  created() {
    this.isLoading = true
    this.$store.dispatch('admins/action_rde_list',{
      page: this.currentPage,
      perPage: this.perPage,
      toast:this.$toast,
      router:this.$router
    });
    this.filter_retailers()
  },
  data(){
    return {
      total_rde:null,
      isLoading: false,
      totalPages:10,
      total:100,
      perPage: 10,
      currentPage:1,
      hasMorePages: true,
      rdes:[]
    }
  },
  computed: {
    ...mapGetters({
      rde_list: 'admins/rde_list',

    }),
  },
  methods:{
    showMore(data){
      console.log(data)
    },
    download_csv(){
      var csv = '';
      this.rdes.forEach(function(row) {
        csv += row.join(',');
        csv += "\n";
      });

      // console.log(csv);
      var hiddenElement = document.createElement('a');
      hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(csv);
      hiddenElement.target = '_blank';
      hiddenElement.download = 'rdes.csv';
      hiddenElement.click();
    },
    filter_retailers(){
      let myresult = []
      this.total_rde = this.rde_list.total

    //  console.log("this.total_rde.next_page_url",this.total_rde.next_page_url)

      if(this.total_rde===undefined || this.total_rde.next_page_url === undefined){
        this.hasMorePages = false
      }
      else this.hasMorePages = this.total_rde.next_page_url !== null;

      let total_rde_count =1
      let total_rde_per_page =1
      if ( typeof(this.total_rde)  !== 'undefined' && typeof(this.total_rde.count) !== 'undefined') {
         total_rde_count = this.total_rde.count
      }else{
        total_rde_count = 0
      }

      if ( typeof(this.total_rde)  !== 'undefined' && typeof(this.total_rde.per_page) !== 'undefined') {
        total_rde_per_page = this.total_rde.per_page
      }else{
        total_rde_per_page = 0
      }

     // let total_rde_count = this.total_rde.count === undefined ?  0 : this.total_rde.count

      // if(this.total_rde  === null || this.total_rde.count === null ){
      //   this.totalPages = 0
      // }else{
        this.totalPages = total_rde_count/total_rde_per_page
      // }


      this.rde_list.data?.forEach((item, index)=>{
        // console.log('rdeList',item)
        myresult.push( [
          item['name']  ,
          item['email']  ,
          item['phone']  ,
          item['country'],
          item['type'],
          item['status'],
          item['id']
        ])
      })
      this.rdes = myresult

      setTimeout(()=>{
        this.isLoading = false
        this.$forceUpdate()
      },4000)

    },
  }
}
</script>

<style scoped>

</style>
