<template>
  <div class="bg-white shadow-2xl w-full p-5">
    <input class="mb-5 w-full border-2 rounded-2xl p-4" type="text" v-model="search" placeholder="Search Table">
    <table class="w-full p-5 ">
      <tr>
        <th  class="border-2 p-2 text-left" v-for="(column, index) in columns" :key="index"  @click="sortRecords(index)">
        {{column}}
        </th>
      </tr>
      <tbody>
            <tr v-for="(retailer , index) in rows" :key="index">
              <td v-for="(item,i) in retailer " :key="i" class="p-5 border-2">
                {{item}}
              </td>
            </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name:"DynamicTable",
  props: ['columns','rowRaws'],
 mounted() {
    this.rows =  [...this.rowRaws]
 },
  data(){
    return {
      search:'',
      row:[],
      rows:[],
      sortIndex: null,
      sortDirection: null

    }
  },
  watch:{
    search: function (newvalue, oldvalue){
      // console.log(newvalue,oldvalue)
      this.onSearch()
    }
},
  methods: {
    sortRecords: function (index){
      if(this.sortDirection  ==  null){
        this.sortDirection = 'asc'
      }
      this.sortIndex = index
      this.rows = this.rows.sort(
          (rowA,rowB)=> {
            if(typeof (rowA[index]) == 'number' || typeof (rowB[index]) == 'number' ){
             // console.log('number sortting')
              if(this.sortDirection === 'asc' || this.sortDirection == null){
                this.sortDirection = 'desc'
                return rowA[index] - rowB[index]
              }else{
                this.sortDirection = 'asc'
                return  rowB[index] - rowA[index]
              }

             // return rowA[index].localeCompare(rowB[index], undefined, {'numeric': true})
            }else{
              return rowA[index].localeCompare(rowB[index])
            }

          }
      )
    },
   onSearch: function (){
     let term = this.search
     let temp = this.rowRaws
     this.rows = temp.filter(
         row => row.join(" ").includes(term)
     )
     //console.log('search result', result)
   }
  }
}

</script>
