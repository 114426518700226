import axios from 'axios'

export default {

    action_search_personnel({ context,state, commit, rootState },payload){ // eslint-disable-line no-unused-vars

        const search_value = payload.search_value
         const url = `${rootState.baseurl}/admin/user/${search_value}`
        const token = rootState.authentication.token
        const headers =  {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization' : 'Bearer '+ token
        }
        // console.log(`action_search_personnel url ${url}`)

        axios.get(url,{headers} ).then(response => {
           payload.toast.success("Fetched successfully")
            console.log('response',response.data)
            commit('setSearchedPersonnel', response.data)
            // console.log('response', response)
        }).catch((error)=>{
            // console.log("error", error)
            payload.toast.error('An error occurred, please try again')
        })


    },

    action_analytics_overview({ context,state, commit, rootState },payload) { // eslint-disable-line no-unused-vars
        const url = `${rootState.baseurl}/admin/overview/analytics`
        const token = rootState.authentication.token
        const headers =  {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization' : 'Bearer '+ token
        }
        axios.get(url,{headers} ).then(response => {
            // payload.toast.success("Fetched successfully")
           // console.log('response',response.data)
            commit('setOverviewAnalytics', response.data)
        }).catch((error)=>{
            payload.toast.error('An error occurred, please try again')
        })
    },
    action_eutelsat_analytics_overview({ context,state, commit, rootState },payload) { // eslint-disable-line no-unused-vars
         const url = `https://api.tizeti.com/api/v1/overview/eutelsat_analytics`
        const token = rootState.authentication.token

        const headers =  {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization' : 'Bearer '+ token
        }
        console.log('action_eutelsat_analytics_overview',url)
        axios.get(url,{headers} ).then(response => {
            // payload.toast.success("Fetched successfully")
            // console.log('response',response.data)
            commit('setOverviewEutelsatAnalytics', response.data)
        }).catch((error)=>{
            payload.toast.error('An error occurred, please try again')
        })
    },
    action_hotspot_eutelsat_signup_breakdown({ context,state, commit, rootState },payload) { // eslint-disable-line no-unused-vars

        // const url = `${rootState.baseurl}/admin/overview/analytics/eutelsat_signup_breakdown`
        const url =    `${rootState.baseurl}/admin/overview/analytics/eutelsat_signup_breakdown`
        const token = rootState.authentication.token
        const headers =  {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization' : 'Bearer '+ token
        }
        axios.get(url,{headers} ).then(response => {
            // payload.toast.success("Fetched successfully")
             console.log('action_hotspot_eutelsat_breakdown response',response.data)
            commit('setHotspotNigeriaUsersBreakdown', response.data.nigeria)
            commit('setHotspotEutelsatNigeriaUsersBreakdown',response.data.nigeria)
            commit('setHotspotGhanaUsersBreakdown',response.data.ghana)
            commit('setHotspotNigeriaRevenue',response.data.nigeria_revenue)
            commit('setHotspotGhanaRevenue',response.data.ghana_revenue)
            commit('setHotspotNigeriaSales',response.data.nigeria_sales)
            commit('setHotspotGhanaSales',response.data.ghana_sales)
            commit('setProductsBreakdown', response.data.products_breakdown)
        }).catch((error)=>{
            payload.toast.error('An error occurred, please try again')
        })
        // setHotspotEutelsatNigeriaUsersBreakdown

    },
    action_hotspot_signup_breakdown({ context,state, commit, rootState },payload) { // eslint-disable-line no-unused-vars
        const url = `${rootState.baseurl}/admin/overview/analytics/signup_breakdown`
        const token = rootState.authentication.token
        const headers =  {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization' : 'Bearer '+ token
        }
        axios.get(url,{headers} ).then(response => {
            // payload.toast.success("Fetched successfully")
           // console.log('action_hotspot_signup_breakdown response',response.data)
            commit('setHotspotNigeriaUsersBreakdown', response.data.nigeria)
            commit('setHotspotGhanaUsersBreakdown',response.data.ghana)
            commit('setHotspotNigeriaRevenue',response.data.nigeria_revenue)
            commit('setHotspotGhanaRevenue',response.data.ghana_revenue)
            commit('setHotspotNigeriaSales',response.data.nigeria_sales)
            commit('setHotspotGhanaSales',response.data.ghana_sales)
            commit('setProductsBreakdown', response.data.products_breakdown)
        }).catch((error)=>{
            payload.toast.error('An error occurred, please try again')
        })
    },
    action_eutelsat_retailer_list({ context,state, commit, rootState },payload) { // eslint-disable-line no-unused-vars
        const page =  payload.page
        const perpage = payload.perPage
        const country = payload.country
        const url = `${rootState.baseurl}/admin/retailer/list/total_eutelsat_retailers?page=${page}&perpage=${perpage}&country=${country}`
        const token = rootState.authentication.token

        const headers =  {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization' : 'Bearer '+ token
        }
        axios.get(url,{headers} ).then(response => {
            if(country === 'Nigeria'){
                commit('setRetailerListNigeria', response.data)
            }else{
                commit('setRetailerListGhana', response.data)
            }


        }).catch((error)=>{
            // payload.toast.error('An error occurred, please try again')
            console.log("fix action_retailer_list","action_retailer_list")
        })
    },
    action_retailer_list({ context,state, commit, rootState },payload) { // eslint-disable-line no-unused-vars
        const page =  payload.page
        const perpage = payload.perPage
        const country = payload.country
        const url = `${rootState.baseurl}/admin/retailer/list/total_retailers?page=${page}&perpage=${perpage}&country=${country}`
        const token = rootState.authentication.token
        const headers =  {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization' : 'Bearer '+ token
        }
        axios.get(url,{headers} ).then(response => {
            if(country === 'Nigeria'){
                commit('setRetailerListNigeria', response.data)
            }else{
                commit('setRetailerListGhana', response.data)
            }


        }).catch((error)=>{
            // payload.toast.error('An error occurred, please try again')
            console.log("fix action_retailer_list","action_retailer_list")
        })
    },
    action_rde_list({ context,state, commit, rootState },payload) { // eslint-disable-line no-unused-vars
        const page =  payload.page
        const perpage = payload.perPage
        const url = `${rootState.baseurl}/admin/rde/list/total_rdes?page=${page}&perpage=${perpage}`
        const token = rootState.authentication.token
        const headers =  {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization' : 'Bearer '+ token
        }
        axios.get(url,{headers} ).then(response => {
            // payload.toast.success("Fetched successfully")
          //  console.log('action_rd_list response',response.data)
            commit('setRdeList', response.data)

        }).catch((error)=>{
            payload.toast.error('An error occurred, please try again')
        })
    },
    action_enable_disable_retailer_account({ context,state, commit, rootState },payload) { // eslint-disable-line no-unused-vars
        // retailer/account/update/status
        const url = `${rootState.baseurl}/admin/retailer/account/update/status`
        const token = rootState.authentication.token
        const headers =  {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization' : 'Bearer '+ token
        }

        const body = {
            user_id: payload.user_id,
            status : payload.status,
        }
        axios.put(url, body,{headers} ).then(response => {
            // console.log('action_enable_disable_retailer_account , response', response)
            window.location.reload(true);
        }).catch((error)=>{
            console.log("error", error)
            // payload.toast.error('An error occurred, please try again')
        })
    },
    action_enable_disable_rde_account({ context,state, commit, rootState },payload) { // eslint-disable-line no-unused-vars
        // retailer/account/update/status
        const url = `${rootState.baseurl}/admin/rde/account/update/status`
        const token = rootState.authentication.token
        const headers =  {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization' : 'Bearer '+ token
        }

        const body = {
            user_id: payload.user_id,
            status : payload.status,
        }
        axios.put(url, body,{headers} ).then(response => {
            console.log('action_enable_disable_rde_account , response', response)
            window.location.reload(true);

        }).catch((error)=>{
            console.log("error", error)
            // payload.toast.error('An error occurred, please try again')
        })
    },
    action_admin_update_retailer_balance({ context,state, commit, rootState },payload) { // eslint-disable-line no-unused-vars
        const url = `${rootState.baseurl}/admin/retailer/wallet/update`
        const token = rootState.authentication.token

        const recharge_type = "administrator_top_up"
        const payment_ref = payload.payment_ref
        const amount = payload.amount
        const currency = payload.currency
        const retailer_id = payload.retailer_id


        const headers =  {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization' : 'Bearer '+ token
        }

        const body =  {
            amount: amount,
            currency: currency,
            recharge_type:recharge_type,
            retailer_id:retailer_id
        }

        if(payment_ref !== null){
            body.payment_ref = payment_ref
        }


        axios.put(url, body,{headers} ).then(response => {
            console.log('Recharged, response', response)
            payload.toast.success('Recharged Successfully')
        }).catch((error)=>{
            console.log("error", error)
            payload.toast.error('An error occurred, please try again')
        })

    },
    action_decommission_create({ context,state, commit, rootState },payload) { // eslint-disable-line no-unused-vars
        const url = `${rootState.baseurl}/admin/decommission/create`
        const token = rootState.authentication.token
        const headers =  {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization' : 'Bearer '+ token
        }

        const body  = {
            retailer_id: payload.retailer_id,
            reason:  payload.reason,
            rde_personnel: payload.rde_personnel,
            status: payload.status
        }

        axios.post(url, body,{headers} ).then(response => {
            payload.toast.success('Retailer Moved Successfully')
            window.location.reload(true);
        }).catch((error)=>{
            payload.toast.error('An error occurred, please try again')
        })
    },
   action_decommission_list({ context,state, commit, rootState },payload) { // eslint-disable-line no-unused-vars
       const url = `${rootState.baseurl}/admin/decommission/list?search_type=ALL&search_value=ALL`
       const token = rootState.authentication.token
       const headers =  {
           'Content-Type': 'application/json',
           'Accept': 'application/json',
           'Authorization' : 'Bearer '+ token
       }

       axios.get(url,{headers} ).then(response => {
          // console.log("action_decommission_list",response.data)
           commit('setDecommissionList', response.data)

       }).catch((error)=>{
           payload.toast.error('An error occurred, please try again')
       })
   },
    action_retailer_purchase_logs({ context,state, commit, rootState },payload) { // eslint-disable-line no-unused-vars
        const url = `${rootState.baseurl}/admin/retailer/list/retailr_logs`
        const token = rootState.authentication.token
        const headers =  {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization' : 'Bearer '+ token
        }

        axios.get(url,{headers} ).then(response => {
            //console.log("action_decommission_list",response.data)
            commit('setRetailersPurchaseList', response.data)

        }).catch((error)=>{
            payload.toast.error('An error occurred, please try again')
        })
    },














    action_create_customer_payments({ context,state, commit, rootState },payload){ // eslint-disable-line no-unused-vars


        let username = payload.username
        let datapack = payload.datapack



        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

        var urlencoded = new URLSearchParams();
        urlencoded.append("action", "createCustomerPaymentXPW");
        urlencoded.append("duration", 1);
        urlencoded.append("username", username);
        urlencoded.append("datapack", datapack);

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: urlencoded,
            redirect: 'follow'
        };

        fetch("https://legacy.tizeti.com/geniatechapi/index.php", requestOptions)
            .then(response =>
                {
                    console.log("response action_create_customer_payments createCustomerPaymentXPW", response)
                }
            )
            .then(result => console.log(result))
            .catch(error => console.log('error', error))

    },

    total_retailer_sales({ context,state, commit, rootState },payload){   // eslint-disable-line no-unused-vars
        const url = rootState.baseurl+"/retailers/sales/customers/count"
        const token = rootState.authentication.token
        axios.get(url, {headers: { 'Accept': 'application/json', 'Authorization' : 'Bearer '+ token}}).then(response => {
           commit('setCustomerCount', response.data)
        }).catch((error)=>{
            console.log("error", error)
            payload.toast.error('An error occurred, please try again')
        })
    },

    action_retailer_wallet_balance ({ context,state, commit, rootState },payload){ // eslint-disable-line no-unused-vars
        const url = rootState.baseurl+"/wallet/1"
        const token = rootState.authentication.token
        axios.get(url, {headers: { 'Accept': 'application/json', 'Authorization' : 'Bearer '+ token}}).then(response => {
         const currency =  response.data[0].country == "Nigeria" ? "₦ ": "GH¢ "
             commit('setCurrency',  currency);
             commit('setWalletBalance', parseInt(response.data[0].balance))
        }).catch((error)=>{
            console.log("error", error)
            payload.toast.error('An error occurred, please try again')
        })
    },
    action_retailer_lifetime_sales_value ({ context,state, commit, rootState },payload){ // eslint-disable-line no-unused-vars
        const url = rootState.baseurl+"/retailer/sales/lifetime"
        const token = rootState.authentication.token
        // console.log("token",token)
        axios.get(url, {headers: { 'Accept': 'application/json', 'Authorization' : 'Bearer '+ token}}).then(response => {
            // const currency =  response.data[0].country == "Nigeria" ? "₦ ": "GH¢ "
            let volume = parseInt(response.data.total_sales_nigeria) ;
            // if(response.data.total_sales_ghana > 0 ){
            //     volume = volume+"| GH¢ "+response.data.total_sales_ghana
            // }

            commit('setSalesVolume', volume)
        }).catch((error)=>{
            console.log("error", error)
            payload.toast.error('An error occurred, please try again')
        })

    },
    action_retailer_total_unread_messages ({ context,state, commit, rootState },payload){ // eslint-disable-line no-unused-vars

        const page = payload.page
        // const url = rootState.baseurl+"/notification"
        const url = `${rootState.baseurl}/notification?page=${page}`
        const token = rootState.authentication.token
        // console.log("token",token)
        axios.get(url, {headers: { 'Accept': 'application/json', 'Authorization' : 'Bearer '+ token}}).then(response => {
            // const currency =  response.data[0].country == "Nigeria" ? "₦ ": "GH¢ "
             commit('setTotalUnread', response.data.total_unread)
             commit('setTotalNotificationMessages', response.data.messages)
        })


    },

    action_create_notification ({ context,state, commit, rootState },payload){ // eslint-disable-line no-unused-vars
        // notification
        const url = rootState.baseurl+`/notification`
        const token = rootState.authentication.token
        const headers =  {
            'Content-Type': 'application/json',
             'Accept': 'application/json',
             'Authorization' : 'Bearer '+ token
            }
        const body = {
            subject: payload.subject,
            message : payload.message,
            sender: payload.sender,
            department: payload.department,
            receiver_id: payload.receiver_id,
            channel: payload.channel,
            receiver_category: payload.receiver_category
        }

        axios.post(url, body,{headers} ).then(response => {
            console.log('action_create_notification , response', response)
        }).catch((error)=>{
            console.log("error", error)
           // payload.toast.error('An error occurred, please try again')
        })

    },
    action_update_unread_messages ({ context,state, commit, rootState },payload){ // eslint-disable-line no-unused-vars
        const url = rootState.baseurl+`/notification/${payload.reference}`
        const token = rootState.authentication.token
        const body = {status:"Read"}
        const headers =  {
            'Content-Type': 'application/json',
             'Accept': 'application/json',
             'Authorization' : 'Bearer '+ token
            }
        axios.put(url, body,{headers} ).then(response => {
            console.log('response', response)
        }).catch((error)=>{
            console.log("error", error)
            payload.toast.error('An error occurred, please try again')
        })
    },
    action_view_retailers ({ context,state, commit, rootState },payload){ // eslint-disable-line no-unused-vars

        const url = `${rootState.baseurl}/admin/retailer/list/total_retailers`
        const token = rootState.authentication.token
        const headers =  {
            'Content-Type': 'application/json',
             'Accept': 'application/json',
             'Authorization' : 'Bearer '+ token
            }
            axios.get(url,{headers} ).then(response => {
                 console.log('action_view_retailers', response.data)
                commit('setRetailers', response.data)
            }).catch((error)=>{
                console.log("error", error)
                payload.toast.error('An error occurred, please try again')
            })

    },
    action_search_customers ({ context,state, commit, rootState },payload){ // eslint-disable-line no-unused-vars

        let value = payload.value

        if(!isNaN( payload.value )){
           value = value.replace("234", "0");
        }


        const url = rootState.baseurl+`/customer/${value}`
        const token = rootState.authentication.token
        const headers =  {
            'Content-Type': 'application/json',
             'Accept': 'application/json',
             'Authorization' : 'Bearer '+ token
            }
            axios.get(url,{headers} ).then(response => {
                 console.log('response', response.data.data)
                 console.log('value', `${payload.value}`)
                commit('setCustomers', response.data.data)
            }).catch((error)=>{
                console.log("error", error)
                payload.toast.error('An error occurred, please try again')
            })

    },
    action_retailer_topup_wallet({ context,state, commit, rootState },payload){// eslint-disable-line no-unused-vars

        const url = `${rootState.baseurl}/wallet/1`
        const token = rootState.authentication.token
       // console.log(url)

        const reference = payload.reference
        const amount = payload.amount
        const currency = 'NGN'

        const headers =  {
            'Content-Type': 'application/json',
             'Accept': 'application/json',
             'Authorization' : 'Bearer '+ token
            }

        const body =  {
            amount: amount,
            payment_ref: reference,
            currency: currency,
            recharge_type:"paystack",
            added_by:"server@tizeti.com"
        }


        console.log("body",body)

        axios.put(url, body,{headers} ).then(response => {
            console.log('Recharged, response', response)
            payload.toast.success('Recharged Successfully')
        }).catch((error)=>{
            console.log("error", error)
            payload.toast.error('An error occurred, please try again')
        })


    },
    action_check_if_retailer_transactions_exists({ context,state, commit, rootState },payload){// eslint-disable-line no-unused-vars
        // ({ context,state, commit, rootState },payload){

            let today = new Date();
            today=today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();

            const reference = payload.reference
            const url = `${rootState.baseurl}/wallet/fetch/history/2022-01-01/${today}/${reference}`
            const token = rootState.authentication.token
            const headers =  {
                'Content-Type': 'application/json',
                 'Accept': 'application/json',
                 'Authorization' : 'Bearer '+ token
                }


                const sendGetRequest = async () => {
                    try {
                        const response = await axios.get(url,{headers});
                        console.log('response.data' ,response.data);

                        if(response.data.data.length>0){
                                return 'stop';
                        }else{
                            return 'start';
                        }

                    }
                    catch (err) {
                        // Handle Error Here
                        console.error('error',err);
                        return 'stop';
                    }
                };

                return sendGetRequest();

    },
    action_retailer_sale_to_customer ({ context,state, commit, rootState },payload){ // eslint-disable-line no-unused-vars
        const url = `${rootState.baseurl}/sales`
        const token = rootState.authentication.token
        const headers =  {
            'Content-Type': 'application/json',
             'Accept': 'application/json',
             'Authorization' : 'Bearer '+ token
            }
        const body = {
            product_uid: payload.product_uid,
            customer_email: payload.customer_email,
            customer_phone: payload.customer_phone
        }
            axios.post(url, body,{headers} ).then(response => {
               // console.log('response', response)
                if(response.data.status){
                   // console.log("eerror succ", response.data)
                    payload.toast.success(response.data.message)
                }else{
                  //  console.log("eerror block", response.data)
                    payload.toast.error(response.data.message)
                }
            }).catch((error)=>{
                console.log("error", error)
                payload.toast.error('An error occurred, please try again')
            })
    }

}
