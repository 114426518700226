<template>
    <div class="flex flex-col bg-white relative h-screen">
      <pheader></pheader>
        <div class="flex flex-col border-1 border-expressgray p-10 mb-5 items-center">
          <div class="mt-10">
            <label for="phone" class="text-sm text-expressgray mb-8">Phone Number</label>
            <div class="flex relative rounded border-2 border-red hover:border-express items-center justify-center w-80 md:w-96">
              <input type="tel"
                     name="phone"
                     @keydown.space.prevent
                     v-model.trim="phone"
                     id="phone"
                     placeholder="Phone Number ,  Format 234xxxxxxxxxx for Nigeria or 233xxxxxxxxx for Ghana"
                     required
                     class="p-4 bg-white  border-0 focus:border-express w-full text-express">

            </div>
          </div>

          <button  class="text-white bg-expressbutton p-3  font-semibold mt-5 capitalize tracking-wide w-80 md:w-96 h-14" type="button" @click="recover" > Recover Password </button>

          <div class="mt-5 md:mr-80">
            <router-link to="/login" class="text-expressgray text-sm border-b-2  hover:border-b-2 hover:border-express">	&#x2190; Back</router-link>
            <p></p>
          </div>

      </div>



    </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: "Recover",
  data(){
    return{
        phone:''
    }
  },
   computed:{
        ...mapGetters({baseurl :'authentication/baseurl'})
  },
  methods:{
    recover:function(){

      if(this.phone.length === 11 ){
        this.phone = `234${this.phone.slice(-10)}`
        // 08189706132
      }
      if(this.phone.length === 10){
        this.phone = `233${this.phone.slice(-9)}`
      }

    if(this.phone.match(/234[0-9]{10}/) || this.phone.match(/233[0-9]{9}/) ){
            this.$store.dispatch('authentication/recoverpassword',{
            phone :  this.phone,
            toast:this.$toast,
            router:this.$router
          })
    }
          else{
            this.$toast.error( 'Incorrect Phone Number Pattern')
          }

    }
  }
}
</script>

<style scoped>

</style>
