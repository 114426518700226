import axios from 'axios'

export default {
    action_products ({ context,state, commit, rootState },payload){ // eslint-disable-line no-unused-vars
        const url = `${rootState.baseurl}/products?currency=${payload.currency}`
        const token = rootState.authentication.token
        axios.get(url, {headers: { 'Accept': 'application/json', 'Authorization' : 'Bearer '+ token}}).then(response => {
          commit('setProducts', response.data.data)
        }).catch((error)=>{
            console.log("error", error)
            payload.toast.error('An error occurred, please try again')
        })
    },

    action_eutelsat_products ({ context,state, commit, rootState },payload){ // eslint-disable-line no-unused-vars
        const url = `${rootState.baseurl}/eutelsat_products/${payload.currency}`
        const token = rootState.authentication.token
        axios.get(url, {headers: { 'Accept': 'application/json', 'Authorization' : 'Bearer '+ token}}).then(response => {
            commit('setEutelsatProducts', response.data.data)
        }).catch((error)=>{
            console.log("error", error)
            payload.toast.error('An error occurred, please try again')
        })
    },

}
