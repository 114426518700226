import mutations from './mutations'
import getters from './getters'
import actions from './actions'

export default {
    namespaced: true,
    state(){
        return {
            // enviroment: 'development',
            searched_personnel: [],
            overview_analytics: [],
            overview_eutelsat_analytics: [],
            hotspot_nigeria_users_breakdown: [],
            hotspot_nigeria_eutelsat_users_breakdown: [],
            hotspot_ghana_users_breakdown: [],
            hotspot_nigeria_sales:[],
            hotspot_nigeria_eutelsat_sales:[],
            hotspot_ghana_sales:[],
            hotspot_nigeria_revenue:[],
            hotspot_ghana_revenue:[],
            products_breakdown:[],
            retailer_list:[],
            retailer_list_nigeria:[],
            retailer_list_ghana:[],
            rde_list:[],
            decommission_list:[],
            retailer_purchase_list: []
        }
    },
    mutations,
    actions,
    getters
}
