<template>


  <div class="h-full">

    <router-view/>

  </div>
</template>

<style>
html,body{
  background-color: #F9F9F9;
}

a:hover, a:visited, a:link, a:active
{
  text-decoration: none;
  border-color: white;
}

.tabs-component-tabs{
  display: flex;
  justify-content: space-around;
}


.nav-item{
  width: 100%;
  text-align: center;
  text-decoration: none;
  padding: 10px;
}
.is-active{
  border-bottom: 2px solid #3C9FA9;
  /*padding: 10px;*/
  text-decoration: none;
  color: #3C9FA9;

}
a > .is-active{
  border-color: white;
}

.boxhead a {
  color: #FFFFFF;
  text-decoration: none;
}


</style>
