export default {
    setCustomerCount(state,payload){
        state.customers_total = payload.data
    },
    setWalletBalance (state,payload){
        state.wallet_balance = payload
    },
    setWalletHistory (state,payload){
        state.wallet_history = payload
    },
    setWalletTopUpHistory (state,payload){
       state.wallet_topup_history = payload
    },
    setCurrency(state,payload){
        state.currency = payload
    },
    setSalesVolume(state,payload){
        state.sales_volume = payload
    },
    setTotalUnread(state,payload){
        state.total_unread = payload
    },
    setTotalNotificationMessages(state,payload){
        state.notification_messages  = payload
    },
    setCustomers(state,payload){
        state.customers = payload
    },
    setRetailers (state,payload){
            state.retailers = payload
    },
    setComputedRetailers(state,payload){
        state.computed_retailers = payload
    }

}
