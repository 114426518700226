export default {
    setCustomers(state,payload){
        state.customers = payload
    },
    setPaymentHistory(state,payload){
        state.payment_history = payload
    },
    setCustomerIssues(state,payload){
        state.customer_issues=payload
    },
    setCustomerList(state,payload){
        state.customer_list=payload
    }

}
