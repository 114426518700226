export default {
    setUser(state, payload){
        document.body.style.zoom = "100%"
        state.user.id = payload.user.id
        state.user.uid = payload.user.uid
        state.user.name = payload.user.name
        state.user.email = payload.user.email
        state.user.phone = payload.user.phone
        state.user.business_name = payload.user.business_name
        state.user.address = payload.user.address
        state.user.primaryaccount = payload.user.account_type
        state.user.country = payload.user.country
        state.user.latitude = payload.user.latitude
        state.user.longitude = payload.user.longitude
        state.user.created_at = payload.user.created_at
        state.user.updated_at = payload.user.updated_at
        state.user.account_types = payload.user.account_types
        state.token = payload.token
        state.user.islogged = true
    },
    removeUser(state){
        state.user.id =null
        state.user.uid =null
        state.user.name = null
        state.user.email = null
        state.user.phone = null
        state.user.business_name = null
        state.user.address = null
        state.user.primaryaccount = null
        state.user.country = null
        state.user.latitude = null
        state.user.longitude = null
        state.user.created_at = null
        state.user.updated_at = null
        state.user.account_types = []
        state.token = null
        state.user.islogged = false
    },
    setPrimaryAccount(state,payload){
        state.user.primaryaccount = payload.account_type
    },
    setBaseUrl(state){
        state.baseurl = 'https://hotspot.tizetiwifi.com/api'
    }
}
