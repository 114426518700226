<template>
  <!-- This example requires Tailwind CSS v2.0+ -->
  <div class="fixed z-10 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
    <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
      <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>

      <!-- This element is to trick the browser into centering the modal contents. -->
      <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
      <div class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
        <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
          <div class="sm:flex sm:items-start">
            <div class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-green-100 sm:mx-0 sm:h-10 sm:w-10">
              <svg class="w-6 h-6 text-green-600" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M5.5 13a3.5 3.5 0 01-.369-6.98 4 4 0 117.753-1.977A4.5 4.5 0 1113.5 13H11V9.413l1.293 1.293a1 1 0 001.414-1.414l-3-3a1 1 0 00-1.414 0l-3 3a1 1 0 001.414 1.414L9 9.414V13H5.5z"></path><path d="M9 13h2v5a1 1 0 11-2 0v-5z"></path></svg>
            </div>
            <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
              <h3 class="text-lg leading-6 font-medium text-gray-900" id="modal-title">Top Up {{customer_phone}} </h3>
              <div class="mt-5">

                <div class="flex flex-col fold-bold text-expressgray space-y-2">
                  <div class="flex items-center space-x-3">
                    <!-- <span>Select Product</span> -->
                    <!-- <select v-model="product"  class="form-control border-4 rounded p-2 text-expressgray">
                      <option v-for="(item,index) in products" v-bind:value="item" :key="index" class="p-2 text-expressgray capitalize">
                        {{ item }}
                      </option>
                    </select> -->
                  </div>
                </div>



                <div class="flex flex-col fold-bold text-expressgray space-y-2 mt-2">
                  <div class="flex items-center space-x-3">
                    <span>Select plan</span>
                    <select v-model="selected_plan"  class="form-control border-4 rounded p-2 text-expressgray">
                      <option v-for="product in cp_products" v-bind:value="[product.uid, product.amount, product.name]" :key="product.uid" class="p-2 text-expressgray">
                        {{ product.name}}
                      </option>
                    </select>
                  </div>

                  <span v-if="selected_plan" class="text-black font-bold">Selected Plan: {{ selected_plan[2] }}</span>
                  <span v-if="wallet_balance" class="text-expressgray">Current Balance: {{ this.wallet_balance }} {{this.currency}}</span>


                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="bg-gray-50 px-4 py-3 flex justify-between w-full mt-5">
          <button v-if="wallet_balance -  selected_plan[1] > 0" type="button" class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm" @click="completeTransaction">Proceed</button>
          <p v-else class="mt-3 w-full text-red-600 inline-flex justify-center rounded-md  border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm" @click="cancelTransaction"> </p>


          <button type="button" class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm" @click="cancelTransaction">Cancel</button>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
//import {expresswifiplans,tizeti_internet_plan,wificallplan,tztproducts} from "@/fakedata/dummydata";
 import { mapGetters } from 'vuex';

export default {
  name: "rechargemodals",
  // props: ["customer_phone","retailer_balance"],
  props:['customer_phone','customer_email','mac_address'],
  emits:["completeTransaction","cancelTransaction"],
   created() {
    this.$store.dispatch('products/action_eutelsat_products',{
                  currency: this.currency,
                  toast:this.$toast,
                  router:this.$router
                });
      
     console.log(this.products, 'productssssssssss');
     console.log(this.eutelsat_products, 'eutesaaaaaaaaaaaaaat');
    // this.cp_products = [...this.products];

     this.cp_products = [...this.eutelsat_products];
    //  this.cp_products = this.cp_products.filter((item)=>{
    //   if(this.user.city === "Abeokuta"){
    //     return   item.name !==  'Daily Plan Unlimited (500 NGN)' &&  item.name !==  'Daily Plan Unlimited (500 NGN)' &&   item.name !==  'Weekly Plan Unlimited (3000 NGN)'  &&  item.name !==  'Bi-Weeky Plan Unlimited (5000 NGN)'
    //   }else{
    //
    //     return item
    //   }
    //
    // })

     let  gen1 = Math.floor(Math.random() * (100000 - 999999) ) + 100000
     let  gen2 = Math.floor(Math.random() * (100000 - 999999) ) + 100000
     this.generated_reference = `Retailer_Eutelsat_Sales_${this.customer_phone}_${this.mac_address}_${Math.abs(gen1)}_${Math.abs(gen2)}`

     console.log(`rechargemodels on created called ${this.generated_reference}`)

     if (this.user.country === 'Nigeria'){
       this.currency = 'NGN'
     }
     else{
       this.currency = 'GHC'
     }

     console.log("logged in user",this.currency)
              this.$store.dispatch('products/action_products',{
                  currency: this.currency,
                  toast:this.$toast,
                  router:this.$router
                });

              this.$store.dispatch('products/action_eutelsat_products',{
                  currency: this.currency,
                  toast:this.$toast,
                  router:this.$router
                });
     },
  data(){
    return{
      selected_plan: [],
      currency: null,
      generated_reference: null,
      cp_products: []
    }
  },
  watch:{
  },

 computed:{
        ...mapGetters({
          user: 'authentication/getUser',
          wallet_balance :'retailers/wallet_balance',
          products: 'products/products',
          eutelsat_products: 'products/eutelsat_products'
        }),
  },



  methods: {
    completeTransaction: function ()  {

      const amount = parseFloat(this.selected_plan[1])
      let datapack = 0
      let duration =  1
      let username = this.mac_address
      console.log('amount', amount)
      console.log('amount type', typeof (amount))


      // if(amount === 50 || amount === 0.8  ){
      //   datapack = 104857600
      //   duration = 1
      //   //  Daily Capped 100mb Plan
      // }
      if(amount === 100  ){
        datapack = 104857600
        duration = 1
        //  Daily Capped 100mb Plan
      }
      else if(amount === 250   ){
        //  datapack = 314572800
        datapack = 367001600
        duration = 3
        //   Daily Capped 300mb Plan
      }
      else if(amount === 450  ){
        //  datapack = 314572800
        datapack = 734003200
        duration = 7
        //   Daily Capped 300mb Plan
      }
      else if(amount === 1000  ){
        //  datapack = 314572800
        datapack = 2097152000
        duration = 30
        //   Daily Capped 300mb Plan
      }
      else if(amount === 400  ){
        //  datapack = 314572800
        datapack = 1048576000
        duration = 1
        //   Daily Capped 300mb Plan
      }
      else if(amount === 350  ){
        //  datapack = 314572800
        datapack = 524288000
        duration = 7
        //   Daily Capped 300mb Plan
      }
      else if(amount === 550  ){
        //  datapack = 314572800
        datapack = 1048576000
        duration = 30
        //   Daily Capped 300mb Plan
      }
      else if(amount === 1250  ){
        //  datapack = 314572800
        datapack = 3145728000
        duration = 30
        //   Daily Capped 300mb Plan
      }


      let subject = `Eutelsat Retailer Sale Notification`
      let message = `
      You just sold a datapack to the customer with the following details \n
      Customer Phone: ${this.customer_phone}, \n
      Customer Email: ${this.customer_email}, \n
      Device ID: ${username}, \n
      Amount: ${this.selected_plan[1]} \n
   `

 let recharge_promise =  this.$store.dispatch('retailers/action_eutelsat_retailer_sale_to_customer',{
                  product_uid: this.selected_plan[0],
                  customer_email: this.customer_email,
                  customer_phone: this.customer_phone,
                    reference: this.generated_reference,
                   datapack:datapack,
                   duration:duration,
                   amount: amount,
                   username:username,
                   subject:subject,
                   message:message,
                   receiver_id: this.user.id,
                   channel: 'single',
                   receiver_category:'retailers',
                   toast:this.$toast,
                   router:this.$router
                })

      console.log('recharge_promise',recharge_promise)

      recharge_promise.then((result)=>{
        console.log('recharge_promise result', result)


        // this.$store.dispatch('retailers/action_retailer_wallet_balance',{
        //   toast:this.$toast,
        //   router:this.$router})
        //
        // this.$store.dispatch('retailers/action_retailer_total_unread_messages',{
        //   toast:this.$toast,
        //   router:this.$router
        // })

        this.$emit('completeTransaction',[this.customer_phone])


      }).catch((error)=>{
        console.log('recharge_promise error', error)
      })











// this.$emit('completeTransaction',[])



    },
    cancelTransaction: function (e) {
      console.log("cancelTransaction triggered",e)
      this.$emit('cancelTransaction')
    }


  }


}
</script>

<style scoped>

</style>
