<template>
  <div class="min-h-screen">
      <h1>Users</h1>
  </div>
</template>

<script>
export default {
 name: "Users",
}
</script>

<style>

</style>