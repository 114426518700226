<template>
  <div class="w-full">
    <div class="nav h-16 flex items-center">
<!--      <img class="ml-5  h-10 my-2 " src="https://res.cloudinary.com/dcsensx8u/image/upload/v1644320869/Hotspot_logo_5.png" alt="wificomlogo">-->
      <img class="ml-5  h-10 my-2 p-3 " src="https://res.cloudinary.com/dcsensx8u/image/upload/v1669473267/Screenshot_2022-11-26_at_2.34.23_PM.png" alt="Tizeti Hotspot Logo">
      <div class="flex flex-row  p-5 space-x-3 items-center justify-between ">
        <div class="flex flex-row space-x-3 items-center m-16">
<!--          <router-link to="/retailer/profile"> <i class="far fa-user text-sm hover:text-express"></i>  </router-link>-->
<!--          <router-link to="/retailer/profile"><p class="text-xs text-expressgray">tundeadejire@tizeti.com</p>  </router-link>-->
        </div>

<!--        <toggle class="mx-5"  title="Dark Mode"  v-on:toggle="toggle"/>-->

<!--        <router-link to="/retailer/logout">-->
<!--          <p class="text-red-600 text-lg mx-5">Log Out</p>-->
<!--        </router-link>-->


      </div>
<!--      <img class="w-32" src="https://res.cloudinary.com/dcsensx8u/image/upload/v1643845886/Screenshot_2022-02-03_at_12.51.19_AM.png" alt="Express Wifi Logo">-->
    </div>
  </div>

</template>

<script>
export default {
  name: "AuthHeaders.vue",
  data(){
    return {
      darkmode:false
    }
  },
  methods:{
    toggle:(value)=>{
      // console.log("Parent Component received event ", value)

  }
  }
}
</script>

<style scoped>

</style>
