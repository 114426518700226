<template>
  <div class="fixed z-10 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
    <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
      <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>

      <!-- This element is to trick the browser into centering the modal contents. -->
      <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
      <div class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
        <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">

          <div class="flex flex-col bg-white rounded p-2 ">
            <div class="flex items-center space-x-4 mb-4 justify-center">
              <i class="fas fa-exclamation-circle text-base text-letterbg "></i> <h3 class="text-2xl leading-6 font-medium text-letterbg text-center" id="modal-title"> Reported Issue  </h3>
            </div>


            <div class="flex  flex-col space-y-3 text-letterbg">
              <p>Created: {{date}}</p>
              <p>Ticket Category: {{category}}</p>
              <p>Ticket Status: {{status}}</p>

              <textarea name="" id="" cols="30" rows="10" class="border-2 border-letterbg rounded-2xl w-full bg-white shadow-2xl p-3" :value="message" disabled></textarea>

            </div>








            <div class="flex  items-center justify-between mt-10 mb-5 bg-gray-50 ">
              <button type="button" class="w-full flex-grow justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm" @click="cancelTransaction">Dismiss</button>
            </div>





          </div>




        </div>

      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "customerisssuemodals.vue",
  emits:["completeTransaction","cancelTransaction"],
  props:["category","message","date","status"],
  methods: {
    completeTransaction: function ()  {
      this.$emit('completeTransaction')
    },
    cancelTransaction: function () {
      this.$emit('cancelTransaction')
    }


  }
}
</script>

<style scoped>

</style>
