<template>
    <div class="flex flex-col p-5"> 
        <h1 class="text-expressgray text-center xl:text-left text-2xl font-semibold font-serif"> Hotspot Retailer Store Locator</h1>

         <div class="flex  mt-8  mb-3">
      <div class="flex relative rounded border-2  justify-between items-center mb-3 h-15 w-full bg-white ">
        <input
            type="text"
            class="w-full px-4 py-1 border-0  h-full"
            v-model.trim="address"
            placeholder="Provide Your Current Address/Location "
        >
        <i class="fas fa-search ml-3 p-2 text-lg hover:text-express hover:scale-100 cursor-pointer" @click="searchRetailer"></i>
      </div>

    </div>

        <div v-if="!isLoading" class="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-4 mt-5 mb-5"> 
            <div v-for="retailer in serverRetailers" :key="retailer.uids"> 
                 <div class="flex flex-col p-8  justify-between bg-white rounded-2xl shadow-2xl h-full text-expressgray">
                   <div class=" flex flex-col "> 
                       <span  class="text-2xl mb-10 font-serif">{{retailer.business_name}}</span>
                       <span class="mb-2">Business Contact: +{{retailer.phone}}</span>
                       <span>Address: {{retailer.address}} </span>
                   </div>
                     <!-- <h2 class="text-base mb-6">{{product.name}}  </h2> -->
                    <div class="flex justify-between mt-10 text-sm">
                            <span class="text-green-500 text-xl"><i class="fas fa-car"></i> </span>
                            <span class="text-green-500 text-xl"> {{retailer.distance}} </span>
                    </div>
                 </div>
             </div>
        </div>

        <div v-else class="mt-20">
             <loading message="Loading, please wait"></loading>
        </div>


    </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
    name:"ViewRetailers",
    created() {
   this.$store.dispatch('retailers/action_view_retailers',{
                    toast:this.$toast,
                    router:this.$router
                      });

                   this.retailers = this.serverRetailers


    },

    data(){
      return {
          address: '',
          retailers:[],
          isLoading: false,
          showDrive: false,
           latitude: null,
           longitude: null
      }
    },
  computed:{
        ...mapGetters({
          serverRetailers: 'retailers/retailers',
          computed_retailers: 'retailers/computed_retailers'
        }),
  },
  methods:{
      searchRetailer: function(){
        //search Retailer Here
        this.isLoading = true
        let vm = this
        console.log("searchRetailer called")
            setTimeout(()=>{
                  if (vm.address !== ''){
                    vm.getLatLng()
                    vm.isLoading= false
                  }else {
                    vm.isLoading= false
                  }

            },4000)
      }



      ,
      getLatLng: function(){
              if(this.address=='')
              return;

              console.log('getLatLng')
        var myHeaders = new Headers();
              myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

            var urlencoded = new URLSearchParams();
            urlencoded.append("action", "get_lat_long");
            urlencoded.append("address", this.address);

          var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: urlencoded,
            redirect: 'follow'
          };

          fetch("https://legacy.tizeti.com/geniatechapi/index.php", requestOptions)
            .then(response => {
             return response.json()
            })
            .then(result => {
                // console.log("result", result)
              if(result.status){
                this.latitude = result.latlng[0]
                this.longitude = result.latlng[1]

                this.$store.dispatch('retailers/action_view_retailers_locator',{
                  latitude: this.latitude,
                  longitude: this.longitude,
                  toast:this.$toast,
                  router:this.$router
                });

              }else{
                this.$toast.error("Invalid Address Provided")
              }





              })
            .catch(error => console.log('error', error))

      },
      GetDrivingDistance: function(lat1, lat2, lon1, lon2){
        var radlat1 = Math.PI * lat1/180
        var radlat2 = Math.PI * lat2/180
        var theta = lon1-lon2
        var radtheta = Math.PI * theta/180
        var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
        dist = Math.acos(dist)
        dist = dist * 180/Math.PI
        dist = dist * 60 * 1.1515
        dist = dist * 1.609344
        dist = Math.floor(dist) * 1000
        // if (unit=="K") { dist = dist * 1.609344 }
        // if (unit=="N") { dist = dist * 0.8684 }
        return dist
      }
  }

}
</script>
<style scoped>

</style>
