<template>
  <!-- This example requires Tailwind CSS v2.0+ -->
  <div class="fixed z-10 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
    <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
      <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>

      <!-- This element is to trick the browser into centering the modal contents. -->
      <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
      <div class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
        <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
          <div class="sm:flex sm:items-start">
            <div class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-green-100 sm:mx-0 sm:h-10 sm:w-10">
              <svg class="w-6 h-6 text-green-600" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M5.5 13a3.5 3.5 0 01-.369-6.98 4 4 0 117.753-1.977A4.5 4.5 0 1113.5 13H11V9.413l1.293 1.293a1 1 0 001.414-1.414l-3-3a1 1 0 00-1.414 0l-3 3a1 1 0 001.414 1.414L9 9.414V13H5.5z"></path><path d="M9 13h2v5a1 1 0 11-2 0v-5z"></path></svg>
            </div>
            <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
              <h3 class="text-lg leading-6 font-medium text-gray-900" id="modal-title">Top Up {{mac_address}} </h3>
              <div class="mt-5">

                <div class="flex flex-col fold-bold text-expressgray space-y-2">
                  <div class="flex items-center space-x-3">
                    <!-- <span>Select Product</span> -->
                    <!-- <select v-model="product"  class="form-control border-4 rounded p-2 text-expressgray">
                      <option v-for="(item,index) in products" v-bind:value="item" :key="index" class="p-2 text-expressgray capitalize">
                        {{ item }}
                      </option>
                    </select> -->
                  </div>
                </div>



                <div class="flex flex-col fold-bold text-expressgray space-y-2 mt-2">
                  <div class="flex items-center space-x-3">
                    <span>Select plan</span>
                    <select v-model="selected_plan"  class="form-control border-4 rounded p-2 text-expressgray">
                      <option v-for="product in cp_products" v-bind:value="[product.uid, product.amount, product.name]" :key="product.uid" class="p-2 text-expressgray">
                        {{ product.name}}
                      </option>
                    </select>
                  </div>

                  <span v-if="selected_plan" class="text-black font-bold">Selected Plan: {{ selected_plan[2] }}</span>


                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="bg-gray-50 px-4 py-3 flex justify-between w-full mt-5">
          <button type="button" class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm" @click="completeTransactionPaystack">Pay with Paystack</button>
          <button type="button" class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm" @click="cancelTransactionPaystack">Cancel</button>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: "add_paystack_model",
  // props: ["customer_phone","retailer_balance"],
  props:['mac_address'],
  emits:["completeTransactionPaystack","cancelTransactionPaystack"],
  created() {
    this.cp_products = [...this.products.filter(item => item.name !== "Daily Plan 100MB (50 NGN)" )
  ];


    if (this.user.country === 'Nigeria'){
      this.currency = 'NGN'
    }
    else{
      this.currency = 'GHC'
    }
    this.$store.dispatch('products/action_eutelsat_products',{
      currency: this.currency,
      toast:this.$toast,
      router:this.$router
    });
  },
  data(){
    return{
      selected_plan: [],
      currency: null,
      cp_products: []
    }
  },
  watch:{
  },

  computed:{
    ...mapGetters({
      user: 'authentication/getUser',
       // products: 'products/products'
      products: 'products/eutelsat_products'
    }),
  },



  methods: {
    completeTransactionPaystack: function ()  {
      const total_amount  =  this.selected_plan[1] * 100
      this.$emit('completeTransactionPaystack', [total_amount])


    },
    cancelTransactionPaystack: function (e) {
      console.log("cancelTransactionPaystack triggered",e)
      this.$emit('cancelTransactionPaystack')
    }


  }


}
</script>

<style scoped>

</style>
