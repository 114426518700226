<template>
  <div class="flex flex-col bg-white h-screen mt-4 px-2">
    <div class="flex justify-between border-b-2 pb-5 pt-3 items-center">
    <h1 class="text-base sm:text-xl text-gray-700 lg:pl-10 lg:text-left font-semibold font-serif">To be Decommissioned</h1>
    <button class="sm:w-40 w-28 sm:h-12 h-10 bg-green-800 text-white">download</button>
    </div>
<div class="overflow-x-scroll">
<table class="w-full">
  <tr class="border-b border-ndigo-100 text-center bg-indigo-50">
    <th class="p-6">Business Name</th>
    <th class="p-6">Location</th>
    <th class="p-6">Telephone</th>
    <th class="p-6">RDE-Personnel</th>
    <th class="p-6">Date</th>
    <th class="p-6">Status</th>
    <th class="p-6">Update</th>
  </tr>
  <tr class="border-b h-14 border-gray-600 text-center" v-for="(item,index) in decommission_list.data" :key="index">
    <td class="p-6">{{item.business_name}}</td>
    <td class="p-6">{{item.address}}</td>
    <td class="p-6">{{item.phone}}</td>
    <td class="p-6">{{item.rde_personnel}}</td>
    <td class="p-6">{{item.created_at}}</td>
    <td class="p-6">{{item.status}}</td>
    <td class="p-6"><button class="disabled border-2 p-2 rounded-2xl">Update Status</button></td>
  </tr>
    </table>
</div>


  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
 name: "Decommissioned",
  created(){
    this.$store.dispatch('admins/action_decommission_list',{
      toast:this.$toast,
      router:this.$router
    });


  },
  computed:{
    ...mapGetters({
      decommission_list: 'admins/decommission_list'
    }),
  },
}
</script>


<style>

</style>
