<template>
  <!-- This example requires Tailwind CSS v2.0+ -->
  <div class="fixed z-10 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
    <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
      <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>

      <!-- This element is to trick the browser into centering the modal contents. -->
      <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
      <div class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
        <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">

          <div class="flex flex-col bg-white rounded p-6">
            <div class="flex items-center justify-center space-x-4 mb-10">
              <svg class="w-6 h-6 text-green-600" fill="currentColor" viewBox="0 0 20 20" ><path fill-rule="evenodd" d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z" clip-rule="evenodd"></path></svg>
              <h3 class="text-lg leading-6 font-medium text-expressgray" id="modal-title">Change Password  </h3>
            </div>

            <div class="flex  items-center justify-between mt-4 ">
              <p class="inline w-1/3">Old Password</p>
              <div class="flex space-x-2 w-2/3 justify-between">
                <input  type="text" class="border-2 rounded p-3 w-full" v-model="oldpassword" autocomplete="password"/>
              </div>
            </div>

             <div class="flex  items-center justify-between mt-4 ">
               <p class="inline w-1/3">New Password</p>
                  <div class="flex space-x-2 w-2/3 justify-between">
                     <input  type="text"  class="border-2 rounded p-3 w-full" v-model="newpassword" autocomplete="password" />
                  </div>
              </div>

            <div class="flex  items-center justify-between mt-4 ">
              <p class="inline w-1/3">Re-enter New Password</p>
              <div class="flex space-x-2 w-2/3 justify-between">
                <input  type="text"  class="border-2 rounded p-3 w-full" v-model="confirm_newpassword" autocomplete="password"  />
              </div>
            </div>

            <div class="flex  items-center justify-between mt-10 mb-5 bg-gray-50 ">
              <button type="button" class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm" @click="completeTransaction">Update Password</button>
              <button type="button" class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm" @click="cancelTransaction">Cancel</button>


            </div>





          </div>




        </div>

      </div>
    </div>
    </div>
</template>

<script>

export default {
  name: "changepasswordmodals",
  props: ["phonenumber"],
  emits:["completeTransaction","cancelTransaction"],
  data(){
    return{

      oldpassword: "",
      newpassword: "",
      confirm_newpassword: ""

    }
  },
  watch:{

  },




  methods: {
    completeTransaction: function ()  {
      if(( this.confirm_newpassword === this.newpassword) && (this.newpassword !== "") && (this.oldpassword !== "") ){
        this.$emit('completeTransaction', [{
          phonenumber: this.phonenumber,
          oldpassword: this.oldpassword,
          newpassword: this.newpassword,


        }])
      }else{
        this.$toast.warning("Passwords do not match ")

      }

    },
    cancelTransaction: function (e) {
      console.log("cancelTransaction triggered",e)
      this.$emit('cancelTransaction')
    }


  }


}
</script>

<style scoped>

</style>
