<template>
   <div class="flex flex-col w-full">
     <div class="flex flex-col space-y-4  ">
       <div class="h-20 bg-inboxbg p-3 flex justify-between items-center">
         <h3 class="text-lg font-serif">Recent Cards</h3>
         <i class="fas fa-minus-circle  cursor-pointer"
            @click="toggleCards('viewcards')"
            :class="{'text-red-500': toggles.viewcards, 'rotate-180':toggles.viewcards,'rotate-0':!toggles.viewcards, 'text-letterbg': !toggles.viewcards}"

         />
       </div>

       <div class="w-full pb-3 pl-3 pr-3  "
            :class="{hidden: toggles.viewcards }" >
         <div class="flex flex-col bg-white p-3 ">
           <p class="text-sm">Team Amadi 17 January</p>
           <p class="text-xs">Created 16th January,2022</p>
         </div>

         <div class="flex flex-col bg-white p-3">
           <p class="text-sm">Team Williams 17 January</p>
           <p class="text-xs">Created 16th January,2022</p>
         </div>

         <div class="flex flex-col bg-white p-3">
           <p class="text-sm">Team Ogechi 17 January</p>
           <p class="text-xs">Created 16th January,2022</p>
         </div>


         <div class="flex flex-col bg-white p-3">
           <p class="text-sm cursor-pointer text-letterbg hover:underline">Show all</p>
         </div>

       </div>







     </div>

     <div class="flex flex-col space-y-4  ">
       <div class="h-20 bg-inboxbg p-3 flex justify-between items-center">
         <h3 class="text-lg font-serif">RDEs</h3>
         <i class="fas fa-minus-circle  cursor-pointer"
            @click="toggleCards('viewrds')"
            :class="{'text-red-500': toggles.viewrds, 'rotate-180':toggles.viewrds,'rotate-0':!toggles.viewrds, 'text-letterbg': !toggles.viewrds}"

         />
       </div>

       <div class="w-full pb-3 pl-3 pr-3  "
            :class="{hidden: toggles.viewrds }" >
         <div class="flex flex-col bg-white p-3 space-y-3 ">
           <p class="text-sm cursor-pointer hover:underline" >Abdulrauf Kabir</p>
           <p class="text-sm cursor-pointer hover:underline">Badaru Shefiur</p>
           <p class="text-sm cursor-pointer hover:underline">Ogunkola Emmanuel</p>
           <p class="text-sm cursor-pointer hover:underline">Chukwudi Charles</p>
         </div>

         <div class="flex flex-col bg-white p-3">
           <p class="text-sm cursor-pointer text-letterbg hover:underline">Show all</p>
         </div>

       </div>







     </div>

     <div class="flex flex-col space-y-4  ">
       <div class="h-20 bg-inboxbg p-3 flex justify-between items-center">
         <h3 class="text-lg font-serif">Retailers</h3>
         <i class="fas fa-minus-circle  cursor-pointer"
            @click="toggleCards('viweretailers')"
            :class="{'text-red-500': toggles.viweretailers, 'rotate-180':toggles.viweretailers,'rotate-0':!toggles.viweretailers, 'text-letterbg': !toggles.viweretailers}"

         />
       </div>

       <div class="w-full pb-3 pl-3 pr-3  "
            :class="{hidden: toggles.viweretailers }" >
         <div class="flex flex-col bg-white p-3 space-y-3 ">
           <p class="text-sm cursor-pointer hover:underline" >Try and See Technologies</p>
           <p class="text-sm cursor-pointer hover:underline">Emmacep Concept</p>
           <p class="text-sm cursor-pointer hover:underline">Kenzo Unisex Saloon</p>
           <p class="text-sm cursor-pointer hover:underline">Monik catering services</p>
         </div>

         <div class="flex flex-col bg-white p-3">
           <p class="text-sm cursor-pointer text-letterbg hover:underline">Show all</p>
         </div>

       </div>







     </div>
   </div>
</template>

<script>
export default {
  name: "rde_layoutContainer",
  data(){
    return {
      toggles: {
        viewcards: false,
        viewrds:false,
        viweretailers:false
      }
    }
  },
  methods: {
    toggleCards:  function (variable){
      this.toggles[variable] = !this.toggles[variable]
    }
  }
}
</script>

<style scoped>

</style>
