<template>
<div class="">
  <div class="flex flex-col rounded shadow-lg bg-white w-full px-7 space-y-6 mt-4 pb-4">
      <h1 class="text-xl text-center text-gray-700 font-semibold uppercase border-b-2 pb-5 p-4 lg:pl-10 lg:text-left font-serif">Send message to</h1>

      <form class="flex flex-col space-y-10">
        <div class="space-y-4">
          <div class="flex space-x-6">
            <select class="" name="to" id="">
              <option value="volvo">To</option>
              <option value="saab">All</option>
            </select>
          <!-- <label class="mr-3" for="to">To:</label> -->
          <input class="w-48 sm:w-60 h-8 border rounded border-gray-400 focus:border-gray-500 focus:outline-none" type="text" name="to"/>
           </div>
        <div class="flex space-x-6">
          <label for="title">Title:</label>
          <input class="w-48 sm:w-60 h-8 border rounded border-gray-400 focus:border-gray-500 focus:outline-none" type="text" name="title"/>
        </div>
        </div>

        <textarea name="message" placeholder="Type here" cols="120" rows="20"
        class="border rounded border-gray-200 focus:border-gray-300 focus:outline-none p-2 placeholder:italic placeholder:text-slate-500"/>

        <input class="w-40 h-10 bg-green-800 text-white rounded" type="submit" name="SEND"/>
      </form>
  </div>
  </div>
</template>

<script>
export default {
 name: "SendMessage",
}
</script>

<style>

</style>
