<template>
<div class="relative w-screen h-full">
  <div class="grid grid-cols-8 gap-1 bg-rdeoverview min-h-screen">

    <rdemenumobile @close="sidebarOpened = false" :sidebaropened="sidebarOpened"/>

       <div class="hidden lg:block col-span-2 overflow-hidden">
       <rdemenu></rdemenu>
       </div>

   <div class="col-span-8 lg:col-span-6 px-1 mb-10 rounded">
    <div class="flex sm:justify-between justify-center items-center w-full bg-white border-b-2 border-gray-200">
      <button type="button"
            class="block lg:hidden sm:pl-4 pl-2"
            @click="sidebarOpened = true">
            
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
              <path stroke-linecap="round" stroke-linejoin="round" d="M4 6h16M4 12h16M4 18h16" />
            </svg>
            </button>
      <rdeheader></rdeheader>
    </div>

    <div class="bg-mainsection mb-10">

        <router-view v-slot="slotProps">
          <transition name="route" mode="out-in">
            <component :is="slotProps.Component"></component>
          </transition>
        </router-view>
      </div>
    </div>

    
  </div>
  <div class="footer flex flex-col absolute inset-x-0 bottom-0 h-14  bg-white text-expressgray border-t-2 rounded shadow-2xl items-center justify-center p-3">
      <p class="text-xs">	&#169; {{ new Date().getFullYear() }}  Powered by <a href="https://tizeti.com"  target="_blank">Tizeti Network Limited</a>  </p>
    </div>
  </div>
</template>

<script>

export default {
  name: "RdeOverView",

  data(){
    return{
     sidebarOpened: false,
    }
  }
}
</script>

<style>



</style>
